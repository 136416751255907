import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useSharedStyles = makeStyles(() => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default
    },

    tableHead: {
        backgroundColor: '#00AB55',
        color: 'white'
    },
    tableTitle: {
        backgroundColor: "rgb(235, 235, 235)",
        width: 150,
        fontWeight:"550"
    }
}));