import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Grid,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, } from '../../components/_dashboard/user';
//
// import USERLIST from '../_mocks_/user';
import { useDispatch, useSelector } from 'react-redux';
import { listCustomers } from '../../redux/slices/customers/listCustomers';
import CustomerMoreMenu from 'src/components/_dashboard/user/CustomerMoreMenu';
import PhonebookDialog from 'src/components/_dashboard/tickets/DetailsDialog/PhonebookDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'items', label: 'Items', alignRight: false },
  { id: 'mpesa', label: 'Mpesa no', alignRight: false },
  // { id: 'phone_book', label: 'Phonebook', alignRight: false },
  { id: 'county', label: 'County', alignRight: false },
  { id: 'wallet', label: 'Wallet', alignRight: false },
  { id: 'registered_by', label: 'Reg. by', alignRight: false },
  { id: 'date_added', label: 'Reg. date', alignRight: false },
  { id: 'tickets', label: 'Tickets', alignRight: false },
  { id: 'product', label: 'Product', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (user) => user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const Customers = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { customers, status } = useSelector(state => state.customers);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(listCustomers());
    }
  }, [status, dispatch]);

  const USERLIST = applySortFilter((customers.results || []), getComparator(order, orderBy), filterName);

  // console.log(customers);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = USERLIST;
  //  applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Customers">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Customers
              </Typography>
            </Stack>

            <Card>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                label={'Search User'}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size='small'>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, full_name, first_compositeitem, mpesa_mobile, county,
                            payment_paybill, date_added, parent_payment_plan, registered_by_agent_name, type } = row;
                          const isItemSelected = selected.indexOf(full_name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, full_name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  {/* <Avatar alt={full_name} src={'/'} /> */}
                                  <Typography variant="subtitle2">
                                    {full_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell align="left">{first_compositeitem}</TableCell> */}
                              <TableCell align="left">{mpesa_mobile}</TableCell>
                              {/* <TableCell align="left">
                                <PhonebookDialog customerDetails={row} />
                              </TableCell> */}
                              <TableCell align="left">{county}</TableCell>
                              <TableCell align="left">{payment_paybill || 'N/A'}</TableCell>
                              <TableCell align="left">{registered_by_agent_name}</TableCell>
                              <TableCell align="left">
                                <Button to='/dashboard/view-customer-tickets' component={RouterLink}>
                                  {3}
                                </Button>
                              </TableCell>
                              <TableCell align="left">{new Date(date_added).toLocaleString
                                ()}</TableCell>
                              <TableCell align="left">{parent_payment_plan}</TableCell>


                              {/*  <TableCell align="left">{company}</TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
                              <TableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={(status === 'banned' && 'error') || 'success'}
                                >
                                  {sentenceCase(type)}
                                </Label>
                              </TableCell>

                              <TableCell align="right">
                                <CustomerMoreMenu customer={id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 20, 40, 80, 120]}
                component="div"
                count={USERLIST.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>

    </Page>
  );
}

export default Customers;
