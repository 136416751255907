import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getTicket = createAsyncThunk('ticket/getTicket', async (ticketId) => {
    console.log(axios.defaults.headers.common.Authorization, 'here again');
    const response = await axios.get(`/v1/tickets/ticket/${ticketId}`);
    console.log(response);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    ticket: {}
}; 

const slice = createSlice({
    name: 'tickets',
    initialState,
    extraReducers: {
        [getTicket.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getTicket.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.ticket = action.payload;
            state.isLoading = false;
        },
        [getTicket.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;