import { Container } from '@mui/material';
import * as React from 'react';

// material
import {
    Typography,
} from '@mui/material';

const WarehouseCover = ({ warehouseDetails }) => {
    const warehouse = (warehouseDetails) ? warehouseDetails : {};
    return (
        <Container>
            <Typography
                variant="h3"
                color="text.primary"
                style={{ padding: 15 }}>
                {warehouse?.name}
            </Typography>
        </Container>
    );
}


export default WarehouseCover;
