import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const addBulkTokenDisbursement = async (payLoad) => {
    try {
        const response = await axios.post(`/v1/portfolio_health/promos/token-bulk-disbursing/`, payLoad);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const addTokenDisbursement = async (payLoad) => {
    try {
        const response = await axios.post(`/v1/portfolio_health/promos/token-disbursing/`, payLoad);
        return response;
    } catch (error) {
        console.log(error);
    }
};
