import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from "react-router";

import { capitalCase } from 'change-case';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Stack,
    Divider,
    Container,
    Typography,
    Grid,
    Card, CardHeader, CardContent,
    Breadcrumbs,
    Tab, Tabs
} from '@mui/material';

// components
import Page from 'src/components/Page';


// redux

const useStyles = makeStyles((theme) => ({
    root: {},
    tabBar: {
        zIndex: 9,
        bottom: 0,
        width: '100%',
        display: 'flex',
        position: 'absolute',

    }
}));

const PagoAccountCreateTicket = () => {
    const { customerId } = useParams();
    const { paygoId } = useParams();

    useEffect(() => {

    }, []);

    return (
        <Page title={`Paygo account create ticket - ${paygoId}`}>
            <Container>
                <Grid container spacing={2}>
                    
                </Grid>
            </Container>
        </Page>
    );
}

export default PagoAccountCreateTicket;
