import {
    Container, Grid, Card, CardHeader,
    List, ListItem, ListItemText,
    CardContent,
    Typography,
    Divider
} from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from 'react-router-dom';

import Page from "src/components/Page";
import CreateTicketForm from "./CreateTicket";
import { getAssociatedAccount } from "src/redux/slices/customers/portfolioHealth";
import { Loader } from "src/components/common/Loader";



const CreateTicket = () => {
    const dispatch = useDispatch();
    const { accountId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [account, setAccount] = useState();
    const [status, setStatus] = useState('loading');

    let callingNumber = (searchParams.get("callingNumber") ? searchParams.get("callingNumber").trim() : '');

    useEffect(() => {
        getAssociatedAccount(accountId).then((res) => {
            setAccount(res.data.results[0]);
            setStatus('idle');
        }).catch((error) => {
            console.error(error);
        });
    }, [accountId, dispatch]);



    return (
        <Page title="Creating tickets">
            <Grid container spacing={2} sx={{ maxWidth: '100%', margin: 2 }}>
                {status === 'loading' ?
                    <Loader sx={{ maxWidth: '100%', margin: 2 }} />
                    :
                    (<>
                        <Grid item xs={7} sm={7} md={7}>
                            <Card>
                                <CardHeader title="New Ticket" />
                                <CardContent>
                                    <CreateTicketForm accountId={account?.id} callingNumber={callingNumber} />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={5} sm={5} md={5}>
                            <Card sx={{ m: '2rem' }}>
                                <CardHeader title="Customer and Account Info" />
                                <CardContent>
                                    <ListItem key={account?.id} alignItems="flex-start">
                                        <ListItemText
                                            primary={account?.full_name}
                                            key={account?.id}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {account?.mobile_number}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem key={account?.id} alignItems="flex-start">
                                        <ListItemText
                                            primary={account?.account_no}
                                            key={account?.id}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography variant="body2" color="text.primary">
                                                        Plan: {account?.payment_plan}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Paybill: {(account?.org_id == 'OR001014') ? '4078479' : '829792'}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Status: {account?.account_status}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Last payment: {(account?.last_payment_datetime !== undefined) ? (moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Next payment: {(account?.next_payment_due_time !== undefined) ? (moment(new Date(account?.next_payment_due_time).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(account?.next_payment_due_time).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </CardContent>
                            </Card>
                            <Card sx={{ m: '2rem' }}>
                                <CardHeader title={`${account?.tickets?.length} existing tickets`} />
                                <CardContent>
                                    {account?.tickets?.map((ticket) => {
                                        return (<>
                                            <ListItem key={ticket?.id} alignItems="flex-start">
                                                <ListItemText
                                                    primary={ticket?.title}
                                                    key={ticket?.id}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography variant="body2" color="text.primary">
                                                                #: {ticket?.id}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Product: {ticket?.component}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Status: {ticket?.status}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Created on: {(ticket?.date !== undefined) ? (moment(new Date(ticket?.date).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(ticket?.date).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Priority: {ticket?.priority}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </>)
                                    })}
                                </CardContent>
                            </Card>
                            <Card sx={{ m: '2rem' }}>
                                <CardHeader title={`${account?.surveys?.length} surveys`} />
                                <CardContent>
                                    {account?.surveys?.map((survey) => {
                                        return (<>
                                            <ListItem key={survey?.id} alignItems="flex-start">
                                                <ListItemText
                                                    primary={survey?.customer_available}
                                                    key={survey?.id}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Created on: {(survey?.date_created !== undefined) ? (moment(new Date(survey?.date_created).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(survey?.date_created).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Phone #: {survey?.phone_number_used}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Reason for not paying: {survey?.reason_for_not_paying}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </>)
                                    })}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>)}
            </Grid>
        </Page>
    )

};
export default CreateTicket;