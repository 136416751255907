import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { filter } from 'lodash';

// material
import {
    Button,
    Box,
    Card, CardHeader, CardMedia, CardContent, CardActions,
    List, ListItem, ListItemText,
    Grid,
    Container,
    Stack,
    Typography,
    OutlinedInput,
    Divider,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';


// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';

// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import CustomerInfoCard from 'src/components/_dashboard/customers/CustomerInfoCard';

// redux
// import { searchCustomerAcross } from 'src/redux/slices/customers/searchCustomerAcross';
import { searchCustomerAcrossCall } from 'src/redux/slices/customers/portfolioHealth';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


const TicketsIndex = () => {

    const dispatch = useDispatch();
    const [filterName, setFilterName] = useState('');

    const [status, setStatus] = useState('idle');
    const [customers, setCustomers] = useState();

    useEffect(() => {

    }, [dispatch]);


    const handleKeypress = (event) => {
        if (event.which === 13) {
            setStatus('loading');
            searchCustomerAcrossCall({ mobile_number: filterName, org_ids: ['OR000081', 'OR001014'] }).then((res) => {
                setCustomers(res.data.results);
                setStatus('idle');
            }).catch((error) => {
                console.error(error);
                setStatus('idle');
            })

        }
    };

    const handleChange = (event) => {
        console.log(event);
        setFilterName(event.target.value);
        // setRows([]);
    };


    return (
        <Page title="Customer search with caller id">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <SearchStyle
                        size='small'
                        value={filterName}
                        onChange={handleChange}
                        onKeyPress={handleKeypress}
                        sx={{ maxWidth: '100%', margin: 2 }}
                        placeholder={'Search customer by mpesa, phone no or nationa id'}
                        startAdornment={
                            <InputAdornment position="start">
                                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        }
                    />

                    <Divider/>
                    
                    {status === 'loading' ?
                        <Loader sx={{ maxWidth: '100%', margin: 2 }} />
                        :
                        (<>
                            <Grid container spacing={2} sx={{ maxWidth: '100%', margin: 1 }}>
                                {customers?.length > 0 ?
                                    (<>
                                        {customers?.map((row) => {
                                            return <div sx={{ maxWidth: '100%', margin: 2 }}>
                                                <Card sx={{ maxWidth: '100%', padding: 2, margin: 2 }} >
                                                    <CardHeader
                                                        sx={{ textDecoration: 'none' }}
                                                        title={row.full_name}
                                                        subheader={`Mobile: ${row.mobile_number} Mpesa: ${row.mpesa_mobile}`}
                                                    />
                                                    <CardContent>
                                                        <List sx={{ width: '100%', maxWidth: 550, bgcolor: 'background.paper' }} >
                                                            {
                                                                row.account.map((account => (
                                                                    <>
                                                                        <ListItem key={account.id} alignItems="flex-start">
                                                                            <ListItemText
                                                                                primary={account.account_no}
                                                                                key={account.id}
                                                                                secondary={
                                                                                    <React.Fragment>
                                                                                        <Typography variant="body2" color="text.primary">
                                                                                            {account.payment_plan}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" color="text.secondary">
                                                                                            {account.account_status}
                                                                                        </Typography>
                                                                                        <Divider />
                                                                                        <Button color="primary" component={RouterLink} to={`/dashboard/ticket/create/${account?.account_no}/?callingNumber=${filterName}`} >
                                                                                            Create ticket
                                                                                        </Button>
                                                                                    </React.Fragment>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                )))
                                                            }
                                                        </List >
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        })}
                                    </>) :
                                    (<>

                                    </>)
                                }
                            </Grid>
                        </>
                        )
                    }
                </Grid>
            </Grid>
        </Page>
    )

};


export default TicketsIndex;