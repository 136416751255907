import axios from 'src/utils/axios';

// ----------------------------------------------------------------------


export const addPhoneNumber = async (phoneBook) => {
    try {
        const response = await axios.post(`/v1/customers/customer/phonebook/`, phoneBook);
        return response;
    } catch (error) {
        console.log(error);
    }

};
