import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import paperFill from '@iconify/icons-eva/briefcase-fill';
import folderFill from '@iconify/icons-eva/folder-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import bulbFill from '@iconify/icons-eva/bulb-fill';
import messageFill from '@iconify/icons-eva/message-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const defaultNavConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'SMS',
    icon: getIcon(messageFill),
    nested: true,
    children: [
      {
        title: 'New sms outbox',
        path: 'sms/new/',
      }
    ]
  },
];


const defaultCSNavConfig = [
  {
    title: 'Tickets',
    icon: getIcon(folderFill),
    nested: true,
    children: [
      {
        title: 'Create',
        path: 'tickets/',
      },
      {
        title: 'List',
        path: 'tickets-list/',
      }
    ]
  },
];


// default tele-collections team
const defaultTCTNavConfig = [
  {
    title: 'Collection survey',
    icon: getIcon(paperFill),
    nested: true,
    children: [
      {
        title: 'Survey questions',
        path: 'portfolio-health/survey-questions/?groupId=85',
      },
      {
        title: 'New survey',
        path: 'portfolio-health/new-customer-survey/?groupId=85',
      },
      {
        title: 'Surveys',
        path: 'portfolio-health/surveys/',
      }
    ]
  },
  {
    title: 'Promo surveys',
    icon: getIcon(folderFill),
    nested: true,
    children: [
      {
        title: 'New Offer Change Survey',
        path: 'portfolio-health/promition-offer-change-survey/?groupId=85',
      },
    ]
  }
];

// default tele-collections agent team
const defaultTCTAdminNavConfig = [
  {
    title: 'Promos',
    icon: getIcon(bulbFill),
    nested: true,
    children: [
      {
        title: 'Disbursements',
        path: 'portfolio-health/promos/token-disbursing/list',
      }
    ]
  }
].concat(defaultTCTNavConfig);

// default lfo team
const defaultLFONavConfig = [
  {
    title: 'Inventory',
    icon: getIcon(lockFill),
    nested: true,
    children: [
      {
        title: 'Warehouses',
        path: 'inventory/warehouses',
      },

      {
        title: 'Stocktake',
        path: 'inventory/stocktake',
      },
      {
        title: 'Recieve stock',
        path: 'inventory/recieve',
      },
      {
        title: 'Dispatch stock',
        path: 'inventory/dispatch',
      },
    ]
  },
];

// all nav configs for admin
const adminNavConfig = defaultNavConfig.concat(defaultTCTAdminNavConfig).concat(defaultLFONavConfig);

// const sidebarConfig = {
//   'customer service rep': defaultNavConfig.concat(defaultCSNavConfig),
//   'tele-collections': defaultNavConfig.concat(defaultTCTNavConfig),
//   'tele-collections-admin': defaultNavConfig.concat(defaultTCTAdminNavConfig),
//   'admin': adminNavConfig,
//   'user': defaultNavConfig
// };

const sidebarConfig = {
  'customer service rep': defaultCSNavConfig,
  'tele-collections': defaultTCTNavConfig,
  'tele-collection': defaultTCTNavConfig,
  'tele-collections-admin': defaultTCTAdminNavConfig,
  'admin': adminNavConfig,
  'user': defaultNavConfig
};

export default sidebarConfig;
