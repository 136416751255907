import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const addCluster = async (cluster) => {
    try {
        const response = await axios.post(`/v1/work_orders/clusters/`, cluster);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const previewCluster = async (tickets_inventory) => {
    try {
        const response = await axios.post(`/v1/work_orders/cluster/preview/`, tickets_inventory);
        return response;
    } catch (error) {
        console.log(error);
    }
};