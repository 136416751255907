import * as React from 'react';

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const WarehouseDetails = ({ warehouseDetails }) => {
    const warehouse = (warehouseDetails) ? warehouseDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableBody>
                        <TableRow
                            hover
                            //key={warehouse.id}
                            tabIndex={-1}
                        >
                            <TableCell>Name</TableCell>
                            <TableCell>{warehouse.name}</TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={warehouse.id}
                            tabIndex={-1}
                        >
                            <TableCell>County</TableCell>
                            <TableCell>{warehouse.county}</TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={warehouse.id}
                            tabIndex={-1}
                        >
                            <TableCell>Zoho</TableCell>
                            <TableCell>{warehouse.zoho}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default WarehouseDetails;
