import { Progressbar } from "./Progressbar";
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        height: '10em'
    },
    center: {
        margin: 'auto',
        width: '15%',
        marginTop: '3em'
    }
}));


export const Loader = (props) => {

    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <div className={classes.center}>
                <Progressbar size={50} />
            </div>
        </Card>
    )

}