import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { filter } from 'lodash';

// material
import {
    Button,
    Box,
    Card, CardHeader, CardMedia, CardContent, CardActions,
    List, ListItem, ListItemText,
    Grid,
    Container,
    Stack,
    Typography,
    Divider
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import CustomerInfoCard from 'src/components/_dashboard/customers/CustomerInfoCard';

// redux
// import { searchCustomerAcross } from 'src/redux/slices/customers/searchCustomerAcross';
import { searchCustomerAcrossCall } from 'src/redux/slices/customers/portfolioHealth';

const InboundCall = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let customerPhoneNumber = searchParams.get("phoneNumber").trim();

    const dispatch = useDispatch();
    const [status, setStatus] = useState('loading');
    const [customers, setCustomers] = useState();

    useEffect(() => {
        searchCustomerAcrossCall({ mobile_number: customerPhoneNumber, org_ids: ['OR000081', 'OR001014'] }).then((res) => {
            setCustomers(res.data.results);
            setStatus('idle');
        }).catch((error) => {
            console.error(error);
        })
    }, [dispatch]);

    const handleCardClick = (row) => {
        // setCustomers([]);
    };

    return (
        <Page title="Customer search with caller id">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {status === 'loading' ?
                            <Loader /> :
                            (<>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                        Caller id <elem style={{ color: '#254A00' }}>{customerPhoneNumber}</elem>
                                    </Typography>
                                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    </Box>
                                </Stack>
                                <Grid container spacing={2}>
                                    {customers.length > 0 ?
                                        (<>
                                            {customers.map((row) => {
                                                return <div>
                                                    <Card sx={{ maxWidth: '100%', padding: 2, margin: 2 }} >
                                                        <CardHeader
                                                            sx={{ textDecoration: 'none' }}
                                                            title={row.full_name}
                                                            subheader={`Mobile: ${row.mobile_number} Mpesa: ${row.mpesa_mobile}`}
                                                        />
                                                        <CardContent>
                                                            <List sx={{ width: '100%', maxWidth: 550, bgcolor: 'background.paper' }} >
                                                                {
                                                                    row.account.map((account => (
                                                                        <>
                                                                            <ListItem key={account.id} alignItems="flex-start">
                                                                                <ListItemText
                                                                                    primary={account.account_no}
                                                                                    key={account.id}
                                                                                    secondary={
                                                                                        <React.Fragment>
                                                                                            <Typography variant="body2" color="text.primary">
                                                                                                {account.payment_plan}
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary">
                                                                                                {account.account_status}
                                                                                            </Typography>
                                                                                            <Divider/>
                                                                                            <Button color="primary" component={RouterLink} to={`/dashboard/ticket/create/${account?.account_no}/?callingNumber=${customerPhoneNumber}`} >
                                                                                                Create ticket
                                                                                            </Button>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                />
                                                                            </ListItem>
                                                                        </>
                                                                    )))
                                                                }
                                                            </List >
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            })}
                                        </>) :
                                        (<>
                                            <Card sx={{ maxWidth: '100%', margin: 2 }} >
                                                <CardHeader
                                                    sx={{ textDecoration: 'none' }}
                                                    title={customerPhoneNumber}
                                                    subheader={`No customer found with this phone number`}
                                                    component={RouterLink} to={`/dashboard/customers/customer/phonenumber-linking?phoneNumber=${customerPhoneNumber}`}
                                                />
                                                <CardContent>

                                                </CardContent>
                                                <CardActions >
                                                    <Button color="primary" title="Link this phone number" component={RouterLink} to={`/dashboard/customers/customer/phonenumber-linking?phoneNumber=${customerPhoneNumber}`}>
                                                        Link this No
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </>)
                                    }
                                </Grid>
                            </>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default InboundCall;