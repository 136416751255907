import * as React from 'react';

import { makeStyles } from '@mui/styles';
// material
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import UpdateClusterTicketsDialog from './UpdateClusterTicketsDialog';

import { Link as RouterLink } from 'react-router-dom';
import { TABLE_HEAD_CLUSTER_TICKET_INVETORY } from './_const';

// ----------------------------------------------------------------------
const TABLE_EXCLUDES = ['ticket__date', 'ticket__customer__mpesa_mobile', 'ticket__status'];

const ClusterTicketsInventory = ({ clusterInfo }) => {
    const cluster = (clusterInfo) ? clusterInfo : {};
    const tickets_inventory = (cluster.tickets_inventory) ? cluster.tickets_inventory : [];
    return (
        <Scrollbar sx={{ padding: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>

                </Typography>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <UpdateClusterTicketsDialog clusterInfo={cluster} />
                </Box>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <TableContainer sx={{ minWidth: 200 }}>
                    <Table>
                        <TableHead sx={{}}>
                            <TableRow >
                                {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                    return (
                                        <TableCell>{th_row.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tickets_inventory.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                    >
                                        {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                            return (
                                                (th_row.hasOwnProperty('field') ?
                                                    <TableCell>
                                                        <TextField
                                                            id={th_row.id}
                                                            size="small"
                                                            disabled
                                                            value={row[th_row.id]}
                                                        />
                                                    </TableCell>
                                                    :
                                                    <TableCell>{row[th_row.id]}</TableCell>
                                                )
                                            )
                                        })}

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Scrollbar>
    );
}

export default ClusterTicketsInventory;
