import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { filter } from 'lodash';

// material
import {
    Button,
    Box,
    Card, CardHeader, CardMedia, CardContent, CardActions,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import CustomerInfoCard from 'src/components/_dashboard/customers/CustomerInfoCard';

// redux
import { listCustomers } from 'src/redux/slices/customers/listCustomers';

const SearchCallerId3cxIndex = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let customerPhoneNumber = searchParams.get("phoneNumber").trim();

    const dispatch = useDispatch();
    const { customers, status } = useSelector(state => state.customers);

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (user) => user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(listCustomers({ mobile_number: customerPhoneNumber, limit: 50 }));
        }
    }, [status, dispatch]);

    const customer_list = applySortFilter((customers.results || []), getComparator(order, orderBy), filterName);

    return (
        <Page title="Caller id 3cx customer search">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {status === 'loading' ?
                            <Loader /> :
                            (<>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                        Caller id search, <elem style={{ color: '#254A00' }}>{customerPhoneNumber}</elem>
                                    </Typography>
                                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    </Box>
                                </Stack>
                                <Grid container spacing={2}>
                                    {customer_list.length > 1 ?
                                        (<>
                                            {customer_list.map((customer) => {
                                                return <CustomerInfoCard customerDetails={customer} s_more={false} />
                                            })}
                                        </>) :
                                        (customer_list.length == 1 ?
                                            <CustomerInfoCard customerDetails={customer_list[0]} s_more={true} /> :
                                            <Card sx={{ maxWidth: '100%', margin: 2 }} >
                                                <CardHeader
                                                    sx={{ textDecoration: 'none' }}
                                                    title={customerPhoneNumber}
                                                    subheader={`No customer found with this phone number`}
                                                    component={RouterLink} to={`/dashboard/customers/customer/phonenumber-linking?phoneNumber=${customerPhoneNumber}`}
                                                />
                                                <CardContent>

                                                </CardContent>
                                                <CardActions >
                                                    <Button color="primary" title="Link this phone number" component={RouterLink} to={`/dashboard/customers/customer/phonenumber-linking?phoneNumber=${customerPhoneNumber}`}>
                                                        Link this No
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        )
                                    }
                                </Grid>
                            </>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default SearchCallerId3cxIndex;