import axios from 'src/utils/axios';

// ----------------------------------------------------------------------


export const createCustomerTicket = async (ticketData) => {
    console.log('-----------------');
    console.log(ticketData);
    console.log('-----------------');
    try {
        const response = await axios.post(`/v1/portfolio_health/associated-account-ticket/`, ticketData);
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
    }

};


export const updateCustomerTicket = async (ticketId, ticketData) => {
    console.log('-----------------');
    console.log(ticketData);
    console.log('-----------------');
    try {
        const response = await axios.patch(`/v1/tickets/ticket/${ticketId}`, ticketData);
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
    }

};