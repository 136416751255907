import { Container } from '@mui/material';
import * as React from 'react';

// material
import {
    Typography,
} from '@mui/material';

const ProfileCover = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Container>
            <Typography
                variant="h3"
                color="text.primary"
                style={{ padding: 15 }}>
                {customer?.full_name}
            </Typography>
        </Container>
    );
}


export default ProfileCover;
