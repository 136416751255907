const titles = [
    { id: 'product malfunction', name: 'Product malfunction' },
    { id: 'payment issue', name: 'Payment issue' },
];

const components = [
    { id: 'solar panel', name: 'Solar panel' },
    { id: 'battery', name: 'Battery' },
    { id: 'torch', name: 'Torch' },
    { id: 'tv', name: 'TV' },
    { id: 'lamp', name: 'Lamp' }
];

const priorities = [
    { id: 'low', value: 'Low' },
    { id: 'normal', value: 'Normal' },
    { id: 'high', value: 'High' },
    { id: 'urgent', value: 'Urgent' },
    { id: 'closed', value: 'Closed' }
];

const ticketStatus = [
    { id: 'open', value: 'Open' },
    { id: 'in progress', value: 'In Progress' },
    { id: 'closed', value: 'Closed' },
];

const ticketTags = [
    { id: 1, name: 'Panel' },
    { id: 2, name: 'Battery' },
    { id: 3, name: 'Radio' }
];

const surveyQuesIO = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' }
];



module.exports = { titles, priorities, ticketStatus, ticketTags, components };