import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser'

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import SurveyQuestionMoreMenu from './SurveyQuestionMoreMenu';
import { toCapitalize } from 'src/utils/dataman';

// ----------------------------------------------------------------------



function split_display_answers(answers) {
    var html_str = '';
    answers.map((answer) => {
        html_str += `${answer}<br/>`;
    });
    return html_str;
}

const SurveyQuestionList = ({ survey_questions }) => {
    const TABLE_HEAD = [];
    if (survey_questions.length > 0) {
        (Object.keys(survey_questions[0])).map((row) => {
            if (row !== 'created_by') {
                TABLE_HEAD.push(
                    { id: row, label: toCapitalize(row), alignRight: false }
                );
            }
        });
    }


    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {survey_questions.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.id}
                                    tabIndex={-1}
                                >
                                    {TABLE_HEAD.map((th_row) => {
                                        return (
                                            (th_row.id == 'answers') ? <TableCell>{parse(split_display_answers(row[th_row.id]))}</TableCell> : <TableCell>{row[th_row.id]}</TableCell>
                                        )
                                    })}
                                    <TableCell align="right">
                                        <SurveyQuestionMoreMenu row={row} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default SurveyQuestionList;
