import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getUser = createAsyncThunk('user/getUser', async (userId) => {
    const response = await axios.get(`/v1/users/user/${userId}`);
    console.log(response);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    fetchUserStatus: "idle",
    error: null,
    user: {}
};

const slice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        [getUser.pending]: (state, action) => {
            state.fetchUserStatus = 'loading';
            state.isLoading = true;
        },
        [getUser.fulfilled]: (state, action) => {
            state.fetchUserStatus = 'succeeded';
            state.user = action.payload;
            state.isLoading = false;
        },
        [getUser.rejected]: (state, action) => {
            state.fetchUserStatus = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;