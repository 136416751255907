import * as React from 'react';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
    Box,
    Card, CardHeader, CardContent, CardMedia, CardActions,
    Divider,
    Stack,
    TextField,
    Button,
    Container,
    Grid,
    DialogContent, DialogContentText, DialogTitle, Dialog, DialogActions,
    Slide,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../../components/Page';
import Scrollbar from 'src/components/Scrollbar';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';

// redux
import { listCustomers } from 'src/redux/slices/customers/listCustomers';
import { addPhoneNumber } from 'src/redux/slices/customers/phoneBook';

const useStyles = makeStyles((theme) => ({
    root: {},
    tabBar: {
        zIndex: 9,
        bottom: 0,
        width: '100%',
        display: 'flex',
        position: 'absolute',

    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerPhoneNumberLinking = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    let customerPhoneNumber = searchParams.get("phoneNumber").trim();

    const [customer, setCustomer] = useState();
    const [customer_name, setCustomerName] = useState('None');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        customer: Yup.string().required('Customer is required'),
        mobile_number: Yup.string().required('Phone number is required'),
    });

    const formik = useFormik({
        initialValues: {
            customer: customer,
            customer_name: customer_name,
            mobile_number: customerPhoneNumber,
            notes: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                var phoneBook = {
                    ...values
                };
                addPhoneNumber(phoneBook).then((response) => {
                    if (response.status === 201) {
                        navigate(`/dashboard/customers/customer/${customer}/details`, { replace: true });
                    }
                }).catch(() => {

                }).finally(() => {

                });
            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Phone number saving failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;


    const handleCustomerChoose = (row) => {
        setCustomer(row.id);
        setCustomerName(row.full_name);
    };


    useEffect(() => {


    }, []);

    const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
        width: 240,
        transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`
        }
    }));


    const SearchCustomerDialog = ({ handleCustomerChoose }) => {

        const [filterName, setFilterName] = useState('');

        const [open, setOpen] = React.useState(false);
        const dispatch_ = useDispatch();

        const [status_, setStatus_] = useState('succeeded');
        const { customers } = useSelector(state => state.customers);
        const [rows, setRows] = useState([]);

        useEffect(() => {
            setRows([]);
            if (status_ === 'idle') {
                dispatch_(listCustomers({ mobile_number: filterName, limit: 50 }));
            }
        }, [status_, dispatch_]);


        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };


        const handleCardClick = (row) => {
            handleCustomerChoose(row);
            setOpen(false);
        };


        const handleKeypress = (event) => {

            if (event.which === 13) {
                // check if enough characters
                if (filterName.length < 8) {
                    enqueueSnackbar('Search term is short.  Enter more than 8', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                    return;
                } else {
                    setStatus_('idle');
                    dispatch_(listCustomers({ mobile_number: filterName, limit: 50 }));
                    setRows((customers.results) ? customers.results : []);
                    console.log(customers);
                }

            }
        };

        const handleChange = (event) => {
            setFilterName(event.target.value);
            setRows([]);
        };


        return (
            <div>
                <Button size="medium" onClick={handleClickOpen} endIcon={<Icon icon={searchFill} />}>
                    Search customer
                </Button>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    fullScreen={true}
                    aria-describedby="alert-dialog-slide-description"
                    style={{
                        marginTop: 50,
                        marginLeft: 300,
                        marginRight: 300,
                        marginBottom: 100
                    }}
                >
                    <DialogTitle>{"Search customer"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Enter phone number or id (Press enter to search). Click on the card to choose
                        </DialogContentText>
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                            <Stack spacing={3} width="100%" style={{ padding: 10 }}>
                                <SearchStyle
                                    size='small'
                                    value={filterName}
                                    onChange={handleChange}
                                    onKeyPress={handleKeypress}
                                    placeholder={'Search customer by mpesa, phone no or nationa id'}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    }
                                />
                                <Divider />
                                <Grid container spacing={2}>
                                    {rows.length > 0 ?
                                        (<>
                                            {rows.map((row) => {
                                                return <div>
                                                    <Card sx={{ maxWidth: '100%', padding: 2 }} >
                                                        <CardHeader
                                                            sx={{ textDecoration: 'none' }}
                                                            title={row.full_name}
                                                            subheader={`Mobile: ${row.mobile_number} Mpesa: ${row.mpesa_mobile}`}
                                                        />
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={(row.profile !== undefined && row.profile.photo_of_house !== null) ? row.profile.photo_of_house : 'https://dummyimage.com/100x100/000/fff'}
                                                            alt="pawame"
                                                        />
                                                        <CardActions >
                                                            <Button color="primary" onClick={() => { handleCardClick(row) }}>
                                                                Choose
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                </div>
                                            })}
                                        </>) :
                                        (<></>)}
                                </Grid>
                            </Stack>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color="error" onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
    return (
        <Page title={`Customer phone number link - ${customerPhoneNumber}`}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Scrollbar>
                            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2 }}>
                                <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                                    <Card>
                                        <CardHeader title="New customer phone number" />
                                        <CardContent>
                                            <FormikProvider value={formik}>
                                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                        <Stack spacing={3} width="100%">
                                                            <TextField
                                                                id="mobile_number"
                                                                disabled
                                                                label="Phone Number"
                                                                size="small"
                                                                {...getFieldProps('mobile_number')}
                                                                error={Boolean(touched.mobile_number && errors.mobile_number)}
                                                                helperText={touched.mobile_number && errors.mobile_number}
                                                            >
                                                            </TextField>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={8} sm={8} md={8}>
                                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                        <div hidden>
                                                                            <TextField
                                                                                id="customer"
                                                                                disabled
                                                                                label="Customer"
                                                                                size="small"
                                                                                {...getFieldProps('customer')}
                                                                                error={Boolean(touched.customer && errors.customer)}
                                                                                helperText={touched.customer && errors.customer}
                                                                            ></TextField>
                                                                        </div>
                                                                        <TextField
                                                                            id="customer_name"
                                                                            label="Customer Name"
                                                                            disabled
                                                                            size="small"
                                                                            {...getFieldProps('customer_name')}
                                                                            error={Boolean(touched.customer_name && errors.customer_name)}
                                                                            helperText={touched.customer_name && errors.customer_name}
                                                                        ></TextField>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} md={4}>
                                                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                        <SearchCustomerDialog handleCustomerChoose={handleCustomerChoose} />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>

                                                            <TextField
                                                                id="notes"
                                                                label="Notes"
                                                                multiline
                                                                rows={3}
                                                                {...getFieldProps('notes')}
                                                                error={Boolean(touched.notes && errors.notes)}
                                                                helperText={touched.notes && errors.notes}
                                                            />
                                                        </Stack>
                                                    </Box>

                                                    <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                                        <Button type='reset' color="error">Reset</Button>
                                                        &nbsp;
                                                        <LoadingButton
                                                            size="small"
                                                            type="submit"
                                                            variant="contained"
                                                            loading={isSubmitting}>
                                                            Save and view
                                                        </LoadingButton>
                                                    </Stack>
                                                </Form>
                                            </FormikProvider>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Box>
                        </Scrollbar>
                    </Grid>
                </Grid>
            </Container>
        </Page >
    );
}

export default CustomerPhoneNumberLinking;
