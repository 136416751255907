import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getItems = createAsyncThunk('warehouse/getItems', async (params_) => {
    const response = await axios.get(`/v1/inventory/item/`, { params: params_ });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    items: []
};

const slice = createSlice({
    name: 'items',
    initialState,
    extraReducers: {
        [getItems.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getItems.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.items = action.payload.results;
            state.isLoading = false;
        },
        [getItems.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },
    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;