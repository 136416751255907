import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import WarehouseMoreMenu from './WarehouseMoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'county', label: 'County', alignRight: false },
    { id: 'stocked', label: 'Stocked', alignRight: false },
    { id: 'in transit', label: 'Intransit', alignRight: false },
    { id: 'installed', label: 'Installed', alignRight: false },
    { id: 'unknown', label: 'Unknown', alignRight: false },
    { id: 'missing', label: 'Missing', alignRight: false },
    { id: 'actions', label: '', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const WarehouseList = ({ warehouses }) => {
    const list = (warehouses !== null) ? warehouses : [];
    return (
        <Container>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 200 }}>
                    <Table>
                        <TableHead sx={{}}>
                            <TableRow >
                                {TABLE_HEAD.map((th_row) => {
                                    return (
                                        <TableCell>{th_row.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                    >
                                        <TableCell>
                                            <RouterLink to={`/dashboard/inventory/warehouse/${row.id}/`} title={row.name}>{row.id}</RouterLink>
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.county}</TableCell>
                                        <TableCell>kits {row.stocked.kits}, items {row.stocked.items}</TableCell>
                                        <TableCell>kits {row['in transit'].kits}, items {row['in transit'].items}</TableCell>
                                        <TableCell>kits {row.unknown.kits}, items {row.unknown.items}</TableCell>
                                        <TableCell>kits {row.missing.kits}, items {row.missing.items}</TableCell>
                                        <TableCell>kits {row.missing.kits}, items {row.missing.items}</TableCell>
                                        <TableCell align="right">
                                            <WarehouseMoreMenu row={row} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Container>

    );
}


export default WarehouseList;
