import * as React from 'react';

import { capitalCase } from 'change-case';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';

// components
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const Profile = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableBody>
                        {Object.entries(customer.profile).map((item) => {
                            return (
                                <TableRow >
                                    <TableCell>{capitalCase((item[0]).replace('-', ' '))}</TableCell>
                                    <TableCell>{(item[1]) !== undefined ? item[1] : ''}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default Profile;
