import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getCluster = createAsyncThunk('cluster/getCluster', async (kitQrCode) => {
    const response = await axios.get(`/v1/work_orders/cluster/${kitQrCode}`);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    cluster: {}
};

const slice = createSlice({
    name: 'cluster',
    initialState,
    extraReducers: {
        [getCluster.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getCluster.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.cluster = action.payload;
            state.isLoading = false;
        },
        [getCluster.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },
    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;