import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getItemCategory = createAsyncThunk('itemCategory/getItemCategory', async (itemId) => {
    const response = await axios.get(`/v1/tickets/categories-by-itemgroup/${itemId}`);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    itemCategory: []
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    extraReducers: {
        [getItemCategory.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getItemCategory.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.itemCategory = action.payload;
            state.isLoading = false;
        },
        [getItemCategory.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;