import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const addCustomerSurvey = async (survey) => {
    try {
        const response = await axios.post(`/v1/portfolio_health/customer-survey/`, survey);
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const changeOffer = async (survey) => {
    try {
        const response = await axios.post(`v1/portfolio_health/promos/change-offer/`, survey);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const addCustomerPromotionOfferChangeSurvey = async (survey) => {
    try {
        const response = await axios.post(`/v1/portfolio_health/promos/change-offer/survey/`, survey);
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const searchCustomer = async (mobile_number, org_id) => {
    var url =  '';
    if(org_id === 'pawame'){
        url = `/v1/customers/customer/`;
    }else if(org_id === 'solrm'){
        url= `/v1/solrm/account/search/`;
    }else{
        url= `/v1/angaza/account/`;
    }
    try {
        const response = await axios.get(url, {
            params: {
                mobile_number,
                org_id
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

// export const searchCustomerAcross = createAsyncThunk('customer/searchCustomerAcross', async (params_) => {
//     const response = await axios.get(`/v1/customers/customer/across/`, { params: params_ });
//     return response.data;
// });


export const searchCustomerAcrossCall = async (params) => {
    var url = `/v1/customers/customer/across/`;
    try {
        const response = await axios.get(url, {
            params
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const getCustomerSurveys = async (account, org_id) => {
    var url = `/v1/portfolio_health/customer-survey/`;
    // var params = (org_id === 'pawame') ? { account:account } : { associated_account: account}
    var params =  '';
    if(org_id === 'pawame'){
        params = { account:account };
    } else if(org_id === 'solrm'){
        params= { solrm_account: account};
    }else{
        params= { associated_account: account};
    }
    try {
        const response = await axios.get(url, {
            params: params
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getAssociatedAccount = async (account_no) => {
    var url = `/v1/portfolio_health/associated-account/`;
    try {
        const response = await axios.get(url, {
            params: {
                account_no
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const listSurveys = async (params_) => {
    try {
        //portfolio_health/customer-survey/?account=31899
        const response = await axios.get(`/v1/portfolio_health/customer-survey/`, {
            params:  params_
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const listSurveyQuestions = async (groups) => {
    try {
        //portfolio_health/customer-survey/?account=31899
        const response = await axios.get(`/v1/portfolio_health/customer-survey/questions/`, {
            params: {
                groups
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const updateSurveyQuestion = async (surveyQuestion, id) => {
    try {
        const response = await axios.put(`/v1/portfolio_health/customer-survey/question/${id}/`, surveyQuestion);
        return response;
    } catch (error) {
        console.log(error);
    }
};

