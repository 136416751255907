import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { SnackbarProvider } from 'notistack';
import infoFill from '@iconify/icons-eva/info-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/system';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => {
    const isLight = theme.palette.mode === 'light';

    const createStyle = {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`
    };

    return {
        containerRoot: {
            '& .MuiCollapse-wrapperInner': {
                width: '100%'
            }
        },
        contentRoot: {
            width: '100%',
            padding: theme.spacing(1.5),
            boxShadow: theme.shadows[25],
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0]
        },
        message: {
            padding: 0,
            fontWeight: theme.typography.fontWeightMedium
        },
        action: {
            marginRight: -4,
            '& svg': {
                width: 20,
                height: 20,
                opacity: 0.48,
                '&:hover': { opacity: 1 }
            }
        },
        info: { ...createStyle },
        success: { ...createStyle },
        warning: { ...createStyle },
        error: { ...createStyle }
    };
});

// ----------------------------------------------------------------------

function SnackbarIcon({ icon, color }) {
    return (
        <Box
            component="span"
            sx={{
                mr: 1.5,
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
                color: `${color}.main`,
                bgcolor: (theme) => alpha(theme.palette[color].main, 0.16)
            }}
        >
            <Icon icon={icon} width={24} height={24} />
        </Box>
    );
}

NotistackProvider.propTypes = {
    children: PropTypes.node
};

function NotistackProvider({ children }) {
    const classes = useStyles();

    return (
        <SnackbarProvider
            dense
            maxSnack={5}
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            iconVariant={{
                success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
                error: <SnackbarIcon icon={infoFill} color="error" />,
                warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
                info: <SnackbarIcon icon={alertCircleFill} color="info" />
            }}
            classes={{
                containerRoot: classes.containerRoot,
                contentRoot: classes.contentRoot,
                message: classes.message,
                action: classes.action,
                variantInfo: classes.info,
                variantSuccess: classes.success,
                variantWarning: classes.warning,
                variantError: classes.error
            }}
        >
            {children}
        </SnackbarProvider>
    );
}

export default NotistackProvider;
