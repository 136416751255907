import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------


const PortfolioHealth = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableBody>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Days off credit</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Repayment Efficiency</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Loan status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default PortfolioHealth;
