import * as React from 'react';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useEffect, useState } from 'react';

// material
import {
    Box,
    TextField,
    Button,
    Grid,
    DialogContent, DialogContentText, DialogTitle, Dialog, DialogActions,
    Table, TableHead, TableRow, TableBody, TableCell, TableContainer,
    Slide,
    Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, useFormikContext } from 'formik';
import Label from 'src/components/Label';

// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import trashFill from '@iconify/icons-eva/trash-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { updateSurveyQuestion } from 'src/redux/slices/customers/portfolioHealth';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EditSurveyQuestion = ({ question }) => {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {

    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAnswer = () => {
        setAnswers(old => [...old, new_answer]);
        setNewAnswer('');
    };

    const handleRemoveAnswer = (answer) => {
        setAnswers(old => old.filter((item) => item !== answer));
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
    });

    const formik = useFormik({
        initialValues: {

        },
        validationSchema: FormSchema,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const surveyQuestion = {
                    answers
                };
                updateSurveyQuestion(surveyQuestion, question.id).then((response) => {
                    if (response.status === 201) {
                        resetForm();
                        setOpen(false);
                        enqueueSnackbar('Survey question updated', {
                            variant: 'success',
                            action: (key) => (
                                <Button size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </Button>
                            )
                        });
                    } else {
                        setErrors({ afterSubmit: response.data.details });
                    }
                }).catch((error) => {
                    setErrors({ afterSubmit: 'Error submitting' });
                });
            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Error saving', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });


    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps,
    } = formik;

    const [answers, setAnswers] = useState(question.answers);
    const [new_answer, setNewAnswer] = useState('');

    return (
        <div style={{ width: '100%' }}>
            <Button size="medium" onClick={handleClickOpen}>
                Edit question
            </Button>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullScreen={true}
                aria-describedby="alert-dialog-slide-description"
                style={{
                    marginTop: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10
                }}
            >
                <DialogTitle>{"Edit question"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Edit the answers available
                    </DialogContentText>
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                            <TextField
                                                id="question_key"
                                                label="question_key"
                                                size="small"
                                                {...getFieldProps('question_key')}
                                                value={question.question_key}
                                                error={Boolean(touched.question_key && errors.question_key)}
                                                helperText={touched.question_key && errors.question_key}
                                                disabled
                                            ></TextField>
                                        </Box>
                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                            <TextField
                                                id="question_value"
                                                label="Question"
                                                size="small"
                                                {...getFieldProps('question_value')}
                                                value={question.question_value}
                                                error={Boolean(touched.question_value && errors.question_value)}
                                                helperText={touched.question_value && errors.question_value}
                                                disabled
                                            ></TextField>
                                        </Box>
                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                            <Label variant="h6">
                                                Answers
                                            </Label>
                                        </Box>
                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                            <TableContainer sx={{ minWidth: 200, maxHeight: 500, overflowY: 'scroll' }}>
                                                <Table size='small'>
                                                    <TableHead sx={{}}>
                                                        <TableRow >
                                                            {['Answer', ''].map((th_row) => {
                                                                return (
                                                                    <TableCell>{th_row}</TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <caption>Available nswers</caption>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell>
                                                                <TextField
                                                                    id="new_answer"
                                                                    label="New answer"
                                                                    size="small"
                                                                    style={{ width: '100%' }}
                                                                    value={new_answer}
                                                                    onChange={(e) => { setNewAnswer(e.target.value); }}
                                                                ></TextField>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button size="small" color='success' onClick={handleAddAnswer} startIcon={<Icon icon={plusFill} />}>Add</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                        {answers.map((row) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                >
                                                                    <TableCell>{row}</TableCell>
                                                                    <TableCell>
                                                                        <Button size="small" color='error' onClick={() => { handleRemoveAnswer(row); }} startIcon={<Icon icon={trashFill} />}>Remove</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                            <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                                <Button type='reset' color="error">Reset</Button>
                                                <LoadingButton
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    loading={isSubmitting}>
                                                    Submit
                                                </LoadingButton>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Form>
                        </FormikProvider>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditSurveyQuestion;
