import * as React from 'react';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Card, CardHeader, CardContent,
    Stack,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// componets
import Scrollbar from 'src/components/Scrollbar';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import cloudFill from '@iconify/icons-eva/cloud-upload-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// ----------------------------------------------------------------------

// utils
import { csvToJson, excelToJson } from 'src/utils/excelToJson';
// ----------------------------------------------------------------------

//  redux

import useAuth from 'src/hooks/useAuth';
import { addBulkTokenDisbursement } from 'src/redux/slices/portfolio_health/promos/addTokenDisbursement';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));

const TABLE_COLUMNS = [
    { key: 'Date', name: 'Date' },
    { key: 'Battery_No', name: 'Battery_No' },
    { key: 'Mpesa_No', name: 'Mpesa_No' },
    { key: 'Days_Inactive_Before', name: 'Days_Inactive_Before' },
    { key: 'Product', name: 'Product' },
    { key: 'System', name: 'System' },
    { key: 'Amount_Paid', name: 'Amount Paid' },
    { key: 'Daily_Rate', name: 'Daily Rate' },
    { key: 'Tokens_purchased', name: 'Tokens purchased' },
    { key: 'Additional_tokens_to_issue', name: 'Additional tokens to issue' },
];

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}




const NewBulkTokenDisbursementForm = () => {
    var now = new Date();

    const { user } = useAuth();

    const dispatch = useDispatch();

    const [items, setItems] = useState([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        user_email: Yup.string().required('User email is required'),
        notes: Yup.string().required('Notes is required'),
    });

    const formik = useFormik({
        initialValues: {
            label: formatDate(now),
            user_email: user.email,
            notes: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                console.log(items);
                if (items.length <= 0) {
                    var error = { code: 100, message: 'Missing items' };
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Stocktake upload failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                } else {
                    addBulkTokenDisbursement({
                        ...values,
                        items
                    }).then((response) => {
                        if (response.status == 201) {
                            setSubmitting(false);
                            resetForm(true);
                            setItems([]);
                            enqueueSnackbar(response.data.message, {
                                variant: 'success',
                                action: (key) => (
                                    <Button size="small" onClick={() => closeSnackbar(key)}>
                                        <Icon icon={closeFill} />
                                    </Button>
                                )
                            });
                        }
                    }).catch(() => {

                    }).finally(() => {

                    })
                }
            } catch (error) {
                console.log(error);
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Stocktake upload failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;

    const handleFileUpload = (e) => {
        excelToJson(e, (data) => {
            console.log(data);
            setItems(data);
        });
    }


    useEffect(() => {

    }, [dispatch]);

    return (
        <Scrollbar>
            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2 }}>
                <Stack spacing={3} width="30%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="" />
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <Stack spacing={3} width="100%">

                                            <TextField
                                                id='label'
                                                type='text'
                                                label="Label"
                                                size="small"
                                                {...getFieldProps('label')}
                                                error={Boolean(touched.label && errors.label)}
                                                helperText={touched.label && errors.label}
                                                disabled={true}
                                            />

                                            <TextField
                                                id='user_email'
                                                type='email'
                                                label="Done by"
                                                size="small"
                                                {...getFieldProps('user_email')}
                                                error={Boolean(touched.user_email && errors.user_email)}
                                                helperText={touched.user_email && errors.user_email}
                                                disabled={true}
                                            />

                                            <TextField
                                                id="notes"
                                                label="Notes"
                                                multiline
                                                rows={2}
                                                {...getFieldProps('notes')}
                                                error={Boolean(touched.notes && errors.notes)}
                                                helperText={touched.notes && errors.notes}
                                            />

                                            <Button
                                                variant='contained'
                                                component='label'
                                                color="info"
                                                startIcon={<Icon icon={cloudFill} />}
                                            >
                                                Upload
                                                <input
                                                    name='avatar'
                                                    accept='xlsx,xls'
                                                    id='contained-button-file'
                                                    type='file'
                                                    hidden
                                                    placeholder='Upload'
                                                    onChange={(e) => {
                                                        handleFileUpload(e)
                                                    }}
                                                />
                                            </Button>
                                        </Stack>
                                    </Box>

                                    <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                        <Button type='reset' color="error">Reset</Button>
                                        <LoadingButton
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}>
                                            Upload and save
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Stack>

                <Stack spacing={3} width="70%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title={`Showing ${items.length} records`} />
                        <CardContent>
                            <TableContainer sx={{ minWidth: 200, }}>

                                <Table>
                                    <TableHead sx={{}}>
                                        <TableRow >
                                            {TABLE_COLUMNS.map((th_row) => {
                                                return (
                                                    <TableCell>{th_row.name}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.code}
                                                    tabIndex={-1}
                                                >
                                                    {TABLE_COLUMNS.map((th_row) => {
                                                        return (
                                                            <TableCell>{(th_row.name == 'Date') ? formatDate(row[th_row.name]) : row[th_row.name]} </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

        </Scrollbar>
    );
}


export default NewBulkTokenDisbursementForm;
