import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { createTheme } from '@mui/material/styles';
import check from '@iconify/icons-eva/checkmark-circle-2-outline';
import close from '@iconify/icons-eva/close-square-outline';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme();

export default function DetailsDialog({ user }) {
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderDate = (date) => {
        if (!date) { return; }
        let newDate = new Date(date).toLocaleString();
        return newDate;
    };

    return (
        <div>
            <Typography title='View Details' sx={{
                cursor: "pointer",

            }} variant="outlined" onClick={handleClickOpen}>
                <Icon color='green' icon={eyeFill} />
            </Typography>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"User Details"}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item sm={12} md={8.5} xs={12}>
                            <Box>
                                <List
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'rgb(235, 235, 235)' }}
                                    subheader={<ListSubheader>Psersonal Details</ListSubheader>}
                                >
                                    <ListItem>
                                        <ListItemText id="switch-list-label-wifi" primary="Email" />
                                        <Typography edge="end">{user?.email}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText id="switch-list-label-wifi" primary="User Name" />
                                        <Typography edge="end"> {user?.username}</Typography>
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText id="switch-list-label-wifi" primary="Name" />
                                        <Typography edge="end">{`${user?.first_name} ${user?.last_name}`}</Typography>
                                    </ListItem>
                                </List>
                            </Box>

                            <Divider />

                            <Box>
                                <List
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'rgb(235, 235, 235)' }}
                                    subheader={<ListSubheader>Important Dates</ListSubheader>}
                                >
                                    <ListItem>
                                        <ListItemText id="switch-list-label-wifi" primary="Date Joined" />
                                        <Typography edge="end"> {renderDate(user?.date_joined)}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText id="switch-list-label-wifi" primary="Last Login" />
                                        <Typography edge="end">{renderDate(user?.last_login)}</Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={3.5} xs={12}>
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'rgb(235, 235, 235)' }}
                                subheader={<ListSubheader>Satus</ListSubheader>}
                            >
                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Is Active" />
                                    <Typography edge="end">
                                        {user?.is_active ?
                                            <Icon color="green" icon={check} /> :
                                            <Icon color="red" icon={close} />
                                        }
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Is Staff" />
                                    <Typography edge="end">
                                        {user?.is_staff ?
                                            <Icon color="green" icon={check} /> :
                                            <Icon color="red" icon={close} />
                                        }
                                    </Typography>
                                </ListItem>

                                <ListItem>
                                    <ListItemText id="switch-list-label-wifi" primary="Is Superuser" />
                                    <Typography edge="end">
                                        {user?.is_superuser ?
                                            <Icon color="green" icon={check} /> :
                                            <Icon color="red" icon={close} />
                                        }
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>


                    </Grid>
                    <DialogContentText sx={{ width: "100vh" }} id="alert-dialog-slide-description">
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


