import xlsx from 'xlsx';


export const excelToJson = (e, callback) => {
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            callback(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }
};

export const jsonToExcel = () => {

};


export const csvToJson = (e, callback) => {
    if (e.target.files) {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = function (e) {
            var jsonData = [];
            var headers = [];
            var rows = e.target.result.split("\r\n");
            console.log(rows);
            for (var i = 0; i < rows.length; i++) {
                var cells = rows[i].split(",");
                var rowData = {};
                for (var j = 0; j < cells.length; j++) {
                    if (i == 0) {
                        var headerName = cells[j].trim();
                        headers.push(headerName);
                    } else {
                        var key = headers[j];
                        if (key) {
                            rowData[key] = cells[j].trim();
                        }
                    }
                }
                //skip the first row (header) data
                if (i != 0) {
                    jsonData.push(rowData);
                }
            }
            callback(jsonData);
            console.log(jsonData);
        }
    }
};