import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getKitItem = createAsyncThunk('warehouse/getKitItem', async (kitItemQrCode) => {
    const response = await axios.get(`/v1/inventory/item/${kitItemQrCode}`);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    kitItem: {}
};

const slice = createSlice({
    name: 'kitItem',
    initialState,
    extraReducers: {
        [getKitItem.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getKitItem.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.kitItem = action.payload.results;
            state.isLoading = false;
        },
        [getKitItem.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },
    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;