// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS = {
    auth: '/auth',
    docs: '/docs'
};

export const PATH_PAGE = {
    auth: {
        root: ROOTS.auth,
        login: path(ROOTS.auth, '/login'),
        loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
        register: path(ROOTS.auth, '/register'),
        registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
        resetPassword: path(ROOTS.auth, '/reset-password'),
        verify: path(ROOTS.auth, '/verify'),
        verifyEmail: path(ROOTS.auth, '/verify-email/')
    },
};


export const PATH_APP = {
    general: {
        root: '/dashboard',
        home: '/dahsboard/app'
    },
};

