import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import SurveyQuestionList from 'src/components/_dashboard/portfolio_health/SurveyQuestionList';
import { Loader } from 'src/components/common/Loader';

// redux
import { listSurveyQuestions } from 'src/redux/slices/customers/portfolioHealth';

const PortfolioHealthSurveyQuestions = () => {

    const [groupIdParams, setgroupIdParams] = useSearchParams();
    let groupId = groupIdParams.get("groupId").trim();
    const [survey_questions, setSurveyQuestions] = useState([]);

    useEffect(() => {
        listSurveyQuestions(groupId).then((response) => {
            if (response.status === 200) {
                setSurveyQuestions(response.data.results);
            }
        }).catch(() => {

        })

    }, []);

    return (
        <Page title="Portfolio health - Survey questions">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Survey questions
                        </Typography>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>

                        </Box>
                    </Stack>
                    {survey_questions.length < 0 ?
                        <Loader /> :
                        (<>
                            <Card>
                                <SurveyQuestionList survey_questions={survey_questions} />
                            </Card>
                        </>
                        )
                    }
                </Grid>
            </Grid>
        </Page>
    )

};


export default PortfolioHealthSurveyQuestions;