

export const multiply_to_array = (data, pow) => {
    var rdata = [];
    for (let i = 0; i < pow; i++) {
        data.id = i;
        rdata.push(data);
    }
    return rdata;
};


export const toCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

