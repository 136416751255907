import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

export const listClusters = createAsyncThunk('customer/listClusters', async (params_) => {
    const response = await axios.get(`/v1/work_orders/clusters/`, { params: params_ });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    clusters: []
};

const slice = createSlice({
    name: 'clusters',
    initialState,
    extraReducers: {
        [listClusters.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [listClusters.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.clusters = action.payload.results;
            state.isLoading = false;
        },
        [listClusters.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;