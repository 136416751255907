import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getCustomerTicket = createAsyncThunk('customerTicket/getCustomerTicket', async (customerId) => {
    const response = await axios.get(`/v1/customers/customer-ticketing/${customerId}`);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    customerTicket: {}
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    extraReducers: {
        [getCustomerTicket.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getCustomerTicket.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.customerTicket = action.payload;
            state.isLoading = false;
        },
        [getCustomerTicket.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;