import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import ClusterList from 'src/components/_dashboard/clusters/ClusterList';
import NewClusterDialog from 'src/components/_dashboard/clusters/NewClusterDialog';

// redux
import { listClusters } from 'src/redux/slices/clusters/listClusters';

const ClusterIndex = () => {
    const dispatch = useDispatch();
    const { clusters, status } = useSelector(state => state.clusters);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(listClusters());
        }
    }, [status, dispatch]);

    return (
        <Page title="Clusters list">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {status === 'loading' ?
                            <Loader /> :
                            (<>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                        Clusters
                                    </Typography>
                                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                        <NewClusterDialog />
                                    </Box>
                                </Stack>
                                <Card>
                                    <ClusterList clusters={clusters} />
                                </Card>
                            </>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default ClusterIndex;