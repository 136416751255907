import * as React from 'react';

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const KitDetailsView = ({ kitDetails }) => {
    const kit = (kitDetails) ? kitDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableBody>
                        <TableRow
                            hover
                            //key={kit.id}
                            tabIndex={-1}
                        >
                            <TableCell>QR Code</TableCell>
                            <TableCell>{kit.qr_code}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default KitDetailsView;
