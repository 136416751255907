import * as React from 'react';
import { capitalCase } from 'change-case';

import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import plusFill from '@iconify/icons-eva/plus-fill';

// material
import {
    Menu, MenuItem, IconButton, ListItemIcon, ListItemText,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'id', alignRight: false },
    { id: 'account_no', label: 'account_no', alignRight: false },
    // { id: 'account_status', label: 'account_status', alignRight: false },
    { id: 'account_type', label: 'account_type', alignRight: false },
    { id: 'contract_no', label: 'contract_no', alignRight: false },
    { id: 'payment_plan', label: 'payment_plan', alignRight: false },
    { id: 'date_added', label: 'date_added', alignRight: false },
    { id: 'id', label: '', alignRight: false },
];

const PaygoAccountMoreMenu = ({ account }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem component={RouterLink} to={`/dashboard/customers/customer/${account.customer}/paygo_account/${account.id}/details`} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Icon icon={plusFill} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText color='primary' primary="View" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem component={RouterLink} to={`/dashboard/customers/customer/${account.customer}/paygo_account/${account.id}/create-ticket`} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Icon icon={eyeFill} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText color='primary' primary="Creat Ticket" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </Menu>
        </>
    );
}

const PaygoAccounts = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{capitalCase((th_row.label).replace('-', ' '))}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customer.account.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.id}
                                    tabIndex={-1}
                                >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.account_no}</TableCell>
                                    {/* <TableCell>{row.account_status}</TableCell> */}
                                    <TableCell>{row.account_type}</TableCell>
                                    <TableCell>{row.contract_no}</TableCell>
                                    <TableCell>{row.payment_plan}</TableCell>
                                    <TableCell>{row.date_added}</TableCell>
                                    <TableCell align="right">
                                        <PaygoAccountMoreMenu account={row} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default PaygoAccounts;
