import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import WarehouseList from 'src/components/_dashboard/inventory/warehouse/WarehouseList';

// redux
import { getWarehouseStocks } from 'src/redux/slices/inventory/getWarehouseStocks';

const WareHouseIndex = () => {
    const dispatch = useDispatch();
    const { warehouses, status } = useSelector(state => state.warehouses);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(getWarehouseStocks());
        }
    }, [status, dispatch]);

    return (
        <Page title="Invertory - Warehouses stock levels">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {status === 'loading' ?
                            <Loader /> :
                            (<>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                        Warehouses - stock levels
                                    </Typography>
                                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    </Box>
                                </Stack>
                                <Card>
                                    <WarehouseList warehouses={warehouses} />
                                </Card>
                            </>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default WareHouseIndex;