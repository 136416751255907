import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getWarehouseStocks = createAsyncThunk('warehouse/getWarehouseStocks', async () => {
    const response = await axios.get(`/v1/inventory/warehouse/stock-status/`,{
        params: { limit: 50 }
    });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    warehouses: []
};

const slice = createSlice({
    name: 'warehouses',
    initialState,
    extraReducers: {
        [getWarehouseStocks.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getWarehouseStocks.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.warehouses = action.payload.data;
            state.isLoading = false;
        },
        [getWarehouseStocks.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;