import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const sendSms = async (smsMessage) => {
    try {
        const response = await axios.post(`/v1/sms/sms/send/`, smsMessage);
        return response;
    } catch (error) {
        console.log(error);
    }
};
