import * as React from 'react';

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

import { TABLE_HEAD_CLUSTER_HISTORY } from './_const';

// ----------------------------------------------------------------------

const ClusterHistory = ({ clusterInfo }) => {
    const cluster = (clusterInfo) ? clusterInfo : {};
    const history = (cluster.history) ? cluster.history : [];
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD_CLUSTER_HISTORY.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.id}
                                    tabIndex={-1}
                                >
                                    {TABLE_HEAD_CLUSTER_HISTORY.map((th_row) => {
                                        return (
                                            <TableCell>{row[th_row.id]}</TableCell>
                                        )
                                    })}

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default ClusterHistory;
