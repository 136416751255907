import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getCustomer = createAsyncThunk('customer/getCustomer', async (customerId) => {
    const response = await axios.get(`/v1/customers/customer/${customerId}`);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    customer: {}
};

const slice = createSlice({
    name: 'customer',
    initialState,
    extraReducers: {
        [getCustomer.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getCustomer.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.customer = action.payload;
            state.isLoading = false;
        },
        [getCustomer.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;