import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const searchCustomer = createAsyncThunk('customer/searchCustomer', async (mobile_number) => {
    const response = await axios.get(`/v1/customers/customer/`, {
        params: {
            mobile_number
        }
    });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    customer: {}
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        resetSearch(state, action) {
            if (action.payload.reset) {
                state.status = 'idle';
            }
        }
    },
    extraReducers: {
        [searchCustomer.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [searchCustomer.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.customer = action.payload;
            state.isLoading = false;
        },
        [searchCustomer.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

export const { resetSearch } = slice.actions;

// Reducer
export default slice.reducer;