import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function CustomerMoreMenu({ customer }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem component={RouterLink} to={`/dashboard/customers/customer/${customer}/details`} sx={{ color: 'text.secondary' }}>
                    {/* <ListItemIcon>
                        <Icon icon={eyeFill} width={24} height={24} />
                    </ListItemIcon> */}
                    <ListItemText color='primary' primary="View" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem component={RouterLink} to={`/dashboard/create-ticket/${customer}`} sx={{ color: 'text.secondary' }}>
                    {/* <ListItemIcon>
                        <Icon icon={addFill} width={24} height={24} />
                    </ListItemIcon> */}
                    <ListItemText color='primary' primary="Creat Ticket" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

            </Menu>
        </>
    );
}
