import axios from '../../utils/axios';


import { createSlice } from '@reduxjs/toolkit';


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    isAuthenticated: false,
    user: {},
    userRole: null
};

const slice = createSlice({
    name: 'authJwt',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // INITIALISE
        getInitialize(state, action) {
            state.isLoading = false;
            state.isAuthenticated = action.payload.isAuthenticated;
            state.user = action.payload.user;
            state.userRole = action.payload.userRole;
        },

        // LOGIN
        loginSuccess(state, action) {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.userRole = action.payload.userRole;
        },

        // REGISTER
        registerSuccess(state, action) {
            state.isAuthenticated = true;
            state.user = action.payload.user;
        },

        // LOGOUT
        logoutSuccess(state) {
            state.isAuthenticated = false;
            state.user = null;
            state.userRole = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    // const decoded = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;

    // return decoded.exp > currentTime;
    return true;
};

const setSession = (accessToken, role) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userRole', role);
        axios.defaults.headers.common.Authorization = `Token ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        delete axios.defaults.headers.common.Authorization;
    }
};

const setAuthUser = (apiUser) => {
    const { displayName, email, id } = apiUser;
    if (displayName) {
        localStorage.setItem('displayName', displayName);
    }
    if (email) {
        localStorage.setItem('userEmail', email);
    }
    if (id) {
        localStorage.setItem('userId', id);
    }
};

export function login({ email, password }) {
    return async (dispatch) => {
        const response = await axios.post('/token-auth/', {
            username: email,
            password
        });
        const accessToken = response.data.token;
        const userApi = response.data.user;
        const userRole = response.data.roles;
        const user = {
            displayName: `${userApi.first_name} ${userApi.last_name}`,
            email: userApi.email,
            id: userApi.id
        };
        console.log(response);
        setAuthUser(user);
        setSession(accessToken, userRole);
        dispatch(slice.actions.loginSuccess({ user, userRole }));
    };
}

export function register({ email, password, firstName, lastName }) {
    return async (dispatch) => {
        const response = await axios.post('/api/account/register', {
            email,
            password,
            firstName,
            lastName
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem('accessToken', accessToken);
        dispatch(slice.actions.registerSuccess({ user }));
    };
}

// ----------------------------------------------------------------------

export function logout() {
    return async (dispatch) => {
        setSession(null, null);
        dispatch(slice.actions.logoutSuccess());
    };
}

// ----------------------------------------------------------------------

export function getInitialize() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const accessToken = window.localStorage.getItem('accessToken');
            const userRole = window.localStorage.getItem('userRole');

            if (accessToken && isValidToken(accessToken)) {
                console.log('here one two');
                setSession(accessToken, userRole);
                const displayName = window.localStorage.getItem('displayName');
                const email = window.localStorage.getItem('userEmail');

                const response = {
                    data: {
                        user: {
                            displayName,
                            email
                        }
                    }
                };
                dispatch(
                    slice.actions.getInitialize({
                        isAuthenticated: true,
                        user: response.data.user,
                        userRole
                    })
                );
            } else {
                dispatch(
                    slice.actions.getInitialize({
                        isAuthenticated: false,
                        user: null
                    })
                );
            }
        } catch (error) {
            console.error(error);
            dispatch(
                slice.actions.getInitialize({
                    isAuthenticated: false,
                    user: null,
                    userRole: null
                })
            );
        }
    };
}
