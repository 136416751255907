import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";

import { capitalCase } from 'change-case';

import { PATH_APP } from 'src/routes/path';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import listFill from '@iconify/icons-eva/list-fill';
import menuFill from '@iconify/icons-eva/menu-fill';
import personFill from '@iconify/icons-eva/person-fill';
import uploadFill from '@iconify/icons-eva/upload-fill';
import awardFill from '@iconify/icons-eva/award-fill';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Stack,
    Divider,
    Container,
    Typography,
    Grid,
    Card, CardHeader, CardContent,
    Breadcrumbs,
    Tab, Tabs
} from '@mui/material';

// components
// import { HeaderDashboard } from 'src/layouts/common';

import Page from '../../components/Page';
import ContactList from 'src/components/_dashboard/customers/phonebook/ContactList';
import ZohoForms from 'src/components/_dashboard/customers/ZohoForms';
import Tickets from 'src/components/_dashboard/customers/Tickets';
import Details from 'src/components/_dashboard/customers/Details';
import ProductKit from 'src/components/_dashboard/customers/ProductKit';
import ProfileCover from 'src/components/_dashboard/customers/ProfileCover';
import { Loader } from 'src/components/common/Loader';
import Profile from 'src/components/_dashboard/customers/Profile';
import PortfolioHealth from 'src/components/_dashboard/customers/PortfolioHealth';
import PaygoAccounts from 'src/components/_dashboard/customers/PaygoAccounts';

// redux
import { getCustomerTicket } from 'src/redux/slices/tickets/getCustomerTicket';

const useStyles = makeStyles((theme) => ({
    root: {},
    tabBar: {
        zIndex: 9,
        bottom: 0,
        width: '100%',
        display: 'flex',
        position: 'absolute',

    }
}));

const Customer = () => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState('Details');

    const dispatch = useDispatch();

    const { customerId } = useParams();
    const { customerTicket } = useSelector((state) => state.customerTicket);
    const [status] = useState('idle');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(getCustomerTicket(customerId));
        }

    }, [status, customerId, dispatch]);

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const TABS = [
        {
            value: 'Details',
            icon: <Icon icon={personFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Details" />
                    <Details customerDetails={customerTicket?.customer}></Details>
                </Card>
            )
        },
        {
            value: 'Paygo Accounts',
            icon: <Icon icon={awardFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Paygo Accounts" />
                    <CardContent>
                        <PaygoAccounts customerDetails={customerTicket?.customer} ></PaygoAccounts>
                    </CardContent>
                </Card>
            )
        },
        {
            value: 'Phonebook',
            icon: <Icon icon={phoneFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Phonebook" />
                    <CardContent>
                        <ContactList customerPhonebook={customerTicket?.customer?.phonebook}></ContactList>
                    </CardContent>
                </Card>
            )
        },
        {
            value: 'Product and Kit',
            icon: <Icon icon={menuFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Product and Kit" />
                    <CardContent>
                        <ProductKit customerDetails={customerTicket?.customer}></ProductKit>
                    </CardContent>
                </Card>
            )
        },
        {
            value: 'Tickets',
            icon: <Icon icon={listFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Tickets" />
                    <CardContent>
                        <Tickets customerTickets={customerTicket?.opentickets} customerDetails={customerTicket}></Tickets>
                    </CardContent>
                </Card>
            )
        },
        {
            value: 'Related forms',
            icon: <Icon icon={fileFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Zoho Forms" />
                    <CardContent>
                        <ZohoForms customerForms={[{ id: customerTicket?.customer?.zoho, label: 'Sales' }]}></ZohoForms>
                    </CardContent>
                </Card>
            )
        },
        {
            value: 'Portfolio health',
            icon: <Icon icon={infoFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Portfolio Health" />
                    <CardContent>
                        <PortfolioHealth customerDetails={customerTicket?.customer}></PortfolioHealth>
                    </CardContent>
                </Card>
            )
        },
        // {
        //     value: 'Payments',
        //     icon: <Icon icon={uploadFill} width={20} height={20} />,
        //     component: (
        //         <Card>
        //             <CardHeader title="Payments" />
        //             <CardContent>

        //             </CardContent>
        //         </Card>)
        // },
        {
            value: 'Profile',
            icon: <Icon icon={personFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Profile" />
                    <CardContent>
                        <Profile customerDetails={customerTicket?.customer}></Profile>
                    </CardContent>
                </Card>)
        }
    ];

    return (
        <Page title={`Customer details - ${customerId}`}>
            <Container>
                <Grid container spacing={2}>
                    {status === 'loading' ?
                        <Loader /> :
                        (<Grid item xs={12} sm={12} md={12}>
                            <Card
                                sx={{
                                    mb: 3,
                                    height: 150,
                                    position: 'relative'
                                }}
                            >
                                <ProfileCover customerDetails={customerTicket?.customer} />
                                <div className={classes.tabBar}>
                                    <Tabs
                                        value={currentTab}
                                        scrollButtons="auto"
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                        onChange={handleChangeTab}
                                    >
                                        {TABS.map((tab) => (
                                            <Tab
                                                disableRipple
                                                key={tab.value}
                                                value={tab.value}
                                                icon={tab.icon}
                                                label={capitalCase(tab.value)}
                                            />
                                        ))}
                                    </Tabs>
                                </div>
                            </Card>

                            {TABS.map((tab) => {
                                const isMatched = tab.value === currentTab;
                                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                            })}
                        </Grid>)
                    }
                </Grid>

            </Container>
        </Page>
    );
}

export default Customer;
