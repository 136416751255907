import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getAdminUsers = createAsyncThunk('adminUsers/getAdminUsers', async () => {
    const response = await axios.get(`/v1/users/user/`, {
        params: {
            is_staff: true,
            is_active: true,
            limit:100
        }
    });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    adminUsersStatus: "idle",
    error: null,
    adminUsers: {}
};

const slice = createSlice({
    name: 'adminUsers',
    initialState,
    extraReducers: {
        [getAdminUsers.pending]: (state, action) => {
            state.adminUsersStatus = 'loading';
            state.isLoading = true;
        },
        [getAdminUsers.fulfilled]: (state, action) => {
            state.adminUsersStatus = 'succeeded';
            state.adminUsers = action.payload;
            state.isLoading = false;
        },
        [getAdminUsers.rejected]: (state, action) => {
            state.adminUsersStatus = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;