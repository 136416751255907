import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const listTickets = async (params_) => {
    try {
        const response = await axios.get(`/v1/portfolio_health/associated-account-ticket/`, {
            params:  params_
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};
