import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import expandFill from '@iconify/icons-eva/expand-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';

// material
import {
    Avatar,
    Button,
    Container,
    Collapse,
    Card, CardHeader, CardMedia, CardContent, CardActions,
    Table, TableHead, TableRow, TableBody, TableCell, TableContainer,
    Typography,
    MenuList, MenuItem,
    ListItemIcon,
} from '@mui/material';

// components
import Scrollbar from 'src/components/Scrollbar';


const CustomerInfoCard = ({ customerDetails, s_more }) => {
    const customer = (customerDetails) ? customerDetails : {};
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card sx={{ maxWidth: '50%', margin: 2 }}>
            <CardHeader
                sx={{ textDecoration: 'none' }}
                avatar={
                    <Avatar sx={{ bgcolor: '#254A00' }} aria-label="customer">
                        {customer.full_name.charAt(0)}
                    </Avatar>
                }
                title={customer.full_name}
                subheader={`Location: ${customer.ward}, ${customer.city}`}
                component={RouterLink} to={`/dashboard/customer/details/${customer?.id}`}
            />

            <CardContent>
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <Icon icon={phoneFill} width={20} height={20} />
                        </ListItemIcon>
                        <Typography variant="inherit">{`Mobile No: ${customer.mobile_number}, Mpesa No: ${customer.mpesa_mobile}`}</Typography>
                    </MenuItem>
                </MenuList>
            </CardContent>
            {(s_more) ? <>
                <CardActions disableSpacing>
                    <Button onClick={handleExpandClick} title="More information">
                        <Icon icon={expandFill} width={20} height={20} />
                    </Button>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardMedia
                        component="img"
                        height="194"
                        image={(customer.profile !== undefined && customer.profile.photo_of_customer !== null) ? customer.profile.photo_of_customer : 'https://dummyimage.com/100x100/000/fff'}
                        alt="Phone"
                    />
                    <CardContent>
                        <Container>
                            <Typography paragraph>Tickets</Typography>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 200 }}>
                                    <Table>
                                        <TableHead sx={{}}>
                                            <TableRow >
                                                {[
                                                    { id: 'id', label: 'Ticket #', alignRight: false },
                                                    { id: 'title', label: 'Title', alignRight: false },
                                                    { id: 'status', label: 'Status', alignRight: false },
                                                    { id: 'date', label: 'Dated', alignRight: false }].map((th_row) => {
                                                        return (
                                                            <TableCell>{th_row.label}</TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {customer.tickets.map((ticket) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={ticket.id}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell component={RouterLink} to={`/dashboard/ticket-details/${ticket?.id}`} sx={{ color: 'text.secondary' }}>{ticket?.id}</TableCell>
                                                        <TableCell>{ticket?.title}</TableCell>
                                                        <TableCell>{ticket?.status}</TableCell>
                                                        <TableCell>{ticket?.date}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                        </Container>
                        <Container style={{ marginTop: 20 }}>
                            <Typography paragraph>Paygo Accounts</Typography>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 200 }}>
                                    <Table>
                                        <TableHead sx={{}}>
                                            <TableRow >
                                                {[
                                                    { id: 'paygo_id', label: 'PayG ID #', alignRight: false },
                                                    // { id: 'paygo_provider', label: 'Provider', alignRight: false },
                                                    // { id: 'days_paid', label: 'Days paid', alignRight: false },
                                                    // { id: 'days_left', label: 'Days left', alignRight: false },
                                                    // { id: 'missed_days', label: 'Days missed', alignRight: false },
                                                    // { id: 'days_to_pay', label: 'Days to pay', alignRight: false },
                                                    { id: 'product_type', label: 'Product type', alignRight: false },
                                                    { id: 'registration_date', label: 'Reg Date', alignRight: false },
                                                    { id: 'new', label: '', alignRight: false }].map((th_row) => {
                                                        return (
                                                            <TableCell>{th_row.label}</TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {customer.paygoaccount.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell>{row?.paygo_id}</TableCell>
                                                        {/* <TableCell>{row?.paygo_provider}</TableCell> */}
                                                        {/* <TableCell>{row?.days_paid}</TableCell>
                                                <TableCell>{row?.days_left}</TableCell>
                                                <TableCell>{row?.missed_days}</TableCell>
                                                <TableCell>{row?.days_to_pay}</TableCell> */}
                                                        <TableCell>{row?.product_type}</TableCell>
                                                        <TableCell>{row?.registration_date}</TableCell>
                                                        <TableCell component={RouterLink} to={`/dashboard/ticket/create/?${row?.paygo_id}`} sx={{ color: 'text.secondary' }}>
                                                            <Icon icon={plusFill} width={20} height={20} title="New ticket" />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                        </Container>
                    </CardContent>
                </Collapse>
            </> : <>

            </>}
        </Card>
    );
}


export default CustomerInfoCard;
