import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'form', label: 'Form', alignRight: false },
    { id: 'creator_link', label: 'Zoho Creator', alignRight: false },
    { id: 'pawahub_link', label: 'Link', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const ZohoForms = ({ customerForms }) => {
    const forms = (customerForms) ? customerForms : [];
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {forms.map((form) => {
                            return (
                                <TableRow
                                    hover
                                    key={form.id}
                                    tabIndex={-1}
                                >
                                    <TableCell>{form.label}</TableCell>
                                    <TableCell><RouterLink to={`https://portal.ignite-ke.com/zoho/customer/${form?.id}/detail/`} target="_blank" title={form.id}>{form.id}</RouterLink></TableCell>
                                    <TableCell component={RouterLink} to={`/dashboard/zoho/customer/${form?.id}`} sx={{ color: 'text.secondary' }}>{form.id}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default ZohoForms;
