import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// icons
import { Icon } from '@iconify/react';
import cloudFill from '@iconify/icons-eva/cloud-upload-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
// ----------------------------------------------------------------------

// material
import {
    Box,
    Button,
    Stack,
    Dialog,
    DialogActions,
    TextField,
    DialogContent,
    Slide,
    Card,
    CardHeader,
    CardContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Divider,
} from '@mui/material';

import { excelToJson } from 'src/utils/excelToJson';
import { ExportJsonCsv } from 'react-export-json-csv';

import { TABLE_HEAD_CLUSTER_TICKET_INVETORY } from './_const';

// components
// ----------------------------------------------------------------------

//  redux
import { previewCluster } from 'src/redux/slices/clusters/addCluster';
// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TABLE_EXCLUDES = ['ticket__date', 'ticket__customer__mpesa_mobile', 'ticket__status'];

export default function UpdateClusterTicketsDialog({ clusterInfo }) {
    const cluster = (clusterInfo) ? clusterInfo : {};
    const tickets_inventory = (cluster.tickets_inventory) ? cluster.tickets_inventory : [];
    const [new_tickets_inventory, setNewTicketsInventory] = useState([]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleKeyPress = (event) => {
        console.log('sd', event.keyCode);
    };

    const handleFileUpload = (e) => {
        excelToJson(e, (data) => {
            var tickets_inventory_ = data.map(function (row) { 
                return {
                    'ticket__id': row.ticket__id,
                    'inventory_item__qr_code': row.inventory_item__qr_code
                }; 
            });
            // check for new rows
            if(tickets_inventory_.length != tickets_inventory.length){
                alert('The 2 sides do not match, more records.');
                return;
            }
            // check for new ticket numbers and disorganization
            for (let i = 0; i < tickets_inventory_.length; i++) {
                if(tickets_inventory_[i].ticket__id != tickets_inventory[i].ticket__id){
                    alert('The 2 sides do not match.');
                    return;
                }
            }
            // setNewTicketsInventory(tickets_inventory_);

            // preview cluster
            previewCluster({ tickets_inventory: tickets_inventory_ }).then((response) => {
                if (response.status == 200) {
                    // set tickets
                    setNewTicketsInventory(response.data.tickets_inventory);
                }
            }).catch(() => {

            }).finally(() => {

            })
        });
    }

    
    const export_headers = [];
    TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((row)=>{
        export_headers.push({
            key: row.id,
            name: row.id
        });
    });
    return (
        <div>
            <Button size="medium" onClick={handleClickOpen} endIcon={<Icon icon={plusFill} />}>
                Update tickets
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullScreen={true}
                aria-describedby="alert-dialog-slide-description"
                style={{
                    marginTop: 50,
                    marginLeft: 100,
                    marginRight: 100,
                    marginBottom: 10
                }}
            >
                <DialogTitle>{`Update tickets in this cluster # ${cluster.id}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Remove / Add tickets to this cluster ({cluster.title})
                    </DialogContentText>
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                        <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                            <Card>
                                <CardHeader title="Cluster current state (Tickets)" />
                                <CardContent>
                                    <TableContainer sx={{ minWidth: 200, }}>
                                        <Table>
                                            <TableHead sx={{}}>
                                                <TableRow >
                                                    {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                                        return (
                                                            <TableCell>{th_row.label}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            {(tickets_inventory.length > 0 ?
                                                <TableBody>
                                                    {tickets_inventory.map((row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={row.id}
                                                                tabIndex={-1}
                                                            >
                                                                {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                                                    return (
                                                                        (th_row.hasOwnProperty('field') ?
                                                                            <TableCell>
                                                                                <TextField
                                                                                    id={row.id}
                                                                                    size="small"
                                                                                    value={row[th_row.id]}
                                                                                    onKeyPress={handleKeyPress}
                                                                                />
                                                                            </TableCell>
                                                                            :
                                                                            <TableCell>{row[th_row.id]}</TableCell>
                                                                        )
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                                :
                                                <></>
                                            )}

                                        </Table>
                                    </TableContainer>
                                    <Divider />
                                    <ExportJsonCsv
                                        style={{
                                            marginTop: 8,
                                            backgroundColor: "#00AB55", color: "white", cursor: "pointer",
                                            padding: 5, borderRadius: 4,
                                            width: 'auto'
                                        }}
                                        fileTitle={`cluster-tickets-${cluster.id}`}
                                        headers={export_headers}
                                        items={tickets_inventory}>
                                        Export tickets for update
                                    </ExportJsonCsv>
                                </CardContent>
                            </Card>
                        </Stack>
                        <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                            <Card>
                                <CardHeader title="Upload updated csv (Tickets)" />
                                <CardContent>
                                    <Button
                                        variant='contained'
                                        component='label'
                                        color="info"
                                        title='Upload updated tickets with Dispatched QR Code'
                                        style={{ marginBottom: 10 }}
                                        startIcon={<Icon icon={cloudFill} />}
                                    >
                                        Upload updated tickets csv
                                        <input
                                            name='avatar'
                                            accept='xlsx,xls'
                                            id='contained-button-file'
                                            type='file'
                                            hidden
                                            placeholder='Upload updated tickets csv'
                                            onChange={(e) => {
                                                handleFileUpload(e)
                                            }}
                                        />
                                    </Button>
                                    <Divider />
                                    <TableContainer sx={{ minWidth: 200, }}>
                                        <Table>
                                            <TableHead sx={{}}>
                                                <TableRow >
                                                    {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                                        return (
                                                            <TableCell>{th_row.label}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            {(new_tickets_inventory.length > 0 ?
                                                <TableBody>
                                                    {new_tickets_inventory.map((row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={row.id}
                                                                tabIndex={-1}
                                                            >
                                                                {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, TABLE_EXCLUDES).map((th_row) => {
                                                                    return (
                                                                        (th_row.hasOwnProperty('field') ?
                                                                            <TableCell>
                                                                                <TextField
                                                                                    id={row.id}
                                                                                    size="small"
                                                                                    value={row[th_row.id]}
                                                                                    disabled
                                                                                />
                                                                            </TableCell>
                                                                            :
                                                                            <TableCell>{row[th_row.id]}</TableCell>
                                                                        )
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                                :
                                                <></>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
