import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authJwtReducer from './slices/authJwt';

// ticket
import getTickets from './slices/tickets/getTickets';
import getTicket from './slices/tickets/getTicket';

// customer
import listCustomers from './slices/customers/listCustomers';
import getCustomer from './slices/customers/getCustomer';
import searchCustomerAcross from './slices/customers/searchCustomerAcross';
import getCustomerTicket from './slices/tickets/getCustomerTicket';

// work orders and clusters
import listClusters from './slices/clusters/listClusters';
import getCluster from './slices/clusters/getCluster';

// import searchCustomer from './slices/customers/searchCustomer';
// import getItemCategory from './slices/tickets/getItemCategory';
// import getCustomers from './slices/customers/getCustomers';
// import getCustomer from './slices/customers/getCustomer';
// import getAdminUser from './slices/users/getAdminUser';
// import getWarehouse from './slices/inventory/getWareHouse';
import getWarehouseStocks from './slices/inventory/getWarehouseStocks';
import getWarehouseStock from './slices/inventory/getWarehouseStock';
import getUser from './slices/users/getUser';
import getKits from './slices/inventory/getKits';
import getKit from './slices/inventory/getKit';
import getItems from './slices/inventory/getItems';
import getKitItem from './slices/inventory/getKitItem';

import getSurveyQuestions from './slices/portfolio_health/getSurveyQuestions';

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['settings']
};

const authPersistConfig = {
    key: 'authJwt',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    // firestore: firestoreReducer,
    authJwt: persistReducer(authPersistConfig, authJwtReducer),
    tickets: getTickets,
    ticket: getTicket,
    // customer: searchCustomer,
    customer: getCustomer,
    customers: listCustomers,
    customers_search_across: searchCustomerAcross,
    customerTicket: getCustomerTicket,

    clusters: listClusters,
    cluster: getCluster,

    // itemCategory: getItemCategory,
    // adminUsers: getAdminUser,
    // warehouse: getWarehouse,
    warehouses: getWarehouseStocks,
    warehouse: getWarehouseStock,
    kits: getKits,
    kit: getKit,
    items: getItems,
    kitItem: getKitItem,

    questions: getSurveyQuestions,

    user: getUser
});

export { rootPersistConfig, rootReducer };
