import * as React from 'react';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Card, CardHeader, CardContent,
    Stack,
    TextField,
    Button,
    MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// componets
import Scrollbar from 'src/components/Scrollbar';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// ----------------------------------------------------------------------

// utils
// ----------------------------------------------------------------------

//  redux

import useAuth from 'src/hooks/useAuth';
import { addTokenDisbursement } from 'src/redux/slices/portfolio_health/promos/addTokenDisbursement';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const NewTokenDisbursementForm = () => {
    var now = new Date();

    const { user } = useAuth();

    const dispatch = useDispatch();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        paygo_id: Yup.string().required('Field required'),
        system: Yup.string().required('Field required'),
        days: Yup.string().required('Field required'),
        tokens_purchased: Yup.string().required('Field required'),
        mpesa_no: Yup.string().required('Field required'),
        amount_paid: Yup.string().required('Field required'),
        notes: Yup.string().required('Field required'),
    });

    const formik = useFormik({
        initialValues: {
            label: 'Single token issuing',
            paygo_id: '',
            system: '',
            days: '',
            mpesa_no: '',
            amount_paid: '',
            tokens_purchased: '',
            date: now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate(),
            user_email: user.email,
            notes: 'This is token promotion issuing for named account.'
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                addTokenDisbursement({
                    ...values,
                }).then((response) => {
                    if (response.status == 201) {
                        setSubmitting(false);
                        resetForm(true);
                        enqueueSnackbar(response.data.message, {
                            variant: 'success',
                            action: (key) => (
                                <Button size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </Button>
                            )
                        });
                    }
                }).catch(() => {

                }).finally(() => {

                })
            } catch (error) {
                console.log(error);
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Token issuing failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;


    useEffect(() => {

    }, [dispatch]);

    return (
        <Scrollbar>
            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2 }}>
                <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="" />
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <Stack spacing={3} width="100%">

                                            <TextField
                                                id='label'
                                                type='text'
                                                label="Label"
                                                size="small"
                                                {...getFieldProps('label')}
                                                error={Boolean(touched.label && errors.label)}
                                                helperText={touched.label && errors.label}
                                                disabled={true}
                                            />

                                            <TextField
                                                id='paygo_id'
                                                type='text'
                                                label="Paygo ID"
                                                size="small"
                                                {...getFieldProps('paygo_id')}
                                                error={Boolean(touched.paygo_id && errors.paygo_id)}
                                                helperText={touched.paygo_id && errors.paygo_id}
                                            />

                                            <TextField
                                                id="system"
                                                label="System"
                                                size="small"
                                                {...getFieldProps('system')}
                                                error={Boolean(touched.system && errors.system)}
                                                helperText={touched.system && errors.system}
                                                select
                                            >
                                                {(['Lumeter', 'Paygops']).map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <TextField
                                                id='days'
                                                type='number'
                                                label="Days"
                                                size="small"
                                                {...getFieldProps('days')}
                                                error={Boolean(touched.days && errors.days)}
                                                helperText={touched.days && errors.days}
                                            />

                                            <TextField
                                                id='tokens_purchased'
                                                type='number'
                                                label="Token Purchased"
                                                size="small"
                                                {...getFieldProps('tokens_purchased')}
                                                error={Boolean(touched.tokens_purchased && errors.tokens_purchased)}
                                                helperText={touched.tokens_purchased && errors.tokens_purchased}
                                            />

                                            <TextField
                                                id='mpesa_no'
                                                type='number'
                                                label="Mpesa No"
                                                size="small"
                                                {...getFieldProps('mpesa_no')}
                                                error={Boolean(touched.mpesa_no && errors.mpesa_no)}
                                                helperText={touched.mpesa_no && errors.mpesa_no}
                                            />

                                            <TextField
                                                id='amount_paid'
                                                type='number'
                                                label="Amount Paid"
                                                size="small"
                                                {...getFieldProps('amount_paid')}
                                                error={Boolean(touched.amount_paid && errors.days)}
                                                helperText={touched.amount_paid && errors.amount_paid}
                                            />

                                            <TextField
                                                id='user_email'
                                                type='email'
                                                label="Done by"
                                                size="small"
                                                {...getFieldProps('user_email')}
                                                error={Boolean(touched.user_email && errors.user_email)}
                                                helperText={touched.user_email && errors.user_email}
                                                disabled={true}
                                            />

                                            <TextField
                                                id='date'
                                                type='text'
                                                label="Date"
                                                size="small"
                                                {...getFieldProps('date')}
                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                                disabled={true}
                                            />
                                            <TextField
                                                id="notes"
                                                label="Notes"
                                                multiline
                                                rows={2}
                                                {...getFieldProps('notes')}
                                                error={Boolean(touched.notes && errors.notes)}
                                                helperText={touched.notes && errors.notes}
                                            />

                                        </Stack>
                                    </Box>
                                    <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                        <Button type='reset' color="error">Reset</Button>
                                        <LoadingButton
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}>
                                            Post
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

        </Scrollbar>
    );
}


export default NewTokenDisbursementForm;
