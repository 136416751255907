import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Box,
    Divider,
    Container,
    Typography,
    Table, TableHead, TableRow, TableBody, TableCell, TableContainer,
    Card, CardHeader, CardContent, CardMedia, CardActionArea, CardActions,
    Button,
} from '@mui/material';

// components
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'mobile_number', label: 'Mobile Number', alignRight: false },
    { id: 'contact_origin', label: 'Contact Origin', alignRight: false },
    { id: 'date_created', label: 'Date Created', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const Details = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableBody>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>First Name</TableCell>
                            <TableCell>{customer.first_name}</TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Middle Name</TableCell>
                            <TableCell>{customer.middle_name}</TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Last Name</TableCell>
                            <TableCell>{customer.last_name}</TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Mobile</TableCell>
                            <TableCell>{customer.mobile_number}</TableCell>
                        </TableRow>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Mpesa no</TableCell>
                            <TableCell>{customer.mpesa_mobile}</TableCell>
                            <TableCell>Mpesa confirmation code</TableCell>
                            <TableCell>{customer.mpesa_confirmation_number}</TableCell>
                        </TableRow>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>City</TableCell>
                            <TableCell>{customer.city}</TableCell>
                            <TableCell>Ward</TableCell>
                            <TableCell>{customer.ward}</TableCell>
                            <TableCell>Nearby school</TableCell>
                            <TableCell>{customer.school_location}</TableCell>
                        </TableRow>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>National ID</TableCell>
                            <TableCell>{customer.national_id_number}</TableCell>
                        </TableRow>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>Date Added</TableCell>
                            <TableCell>{customer.date_added}</TableCell>
                            <TableCell>Date Validated</TableCell>
                            <TableCell>{customer.date_validated}</TableCell>
                            <TableCell>Date Last sale</TableCell>
                            <TableCell>{customer.date_of_last_sale}</TableCell>
                        </TableRow>

                        <TableRow
                            hover
                            //key={customer.id}
                            tabIndex={-1}
                        >
                            <TableCell>
                                <Card sx={{ maxWidth: 250 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={(customer.profile !== undefined && customer.profile.photo_of_customer !== null) ? customer.profile.photo_of_customer : 'https://dummyimage.com/100x100/000/fff'}
                                            alt="pawame"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">Photo of customer</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </TableCell>

                            <TableCell>
                                <Card sx={{ maxWidth: 250 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={(customer.profile !== undefined && customer.profile.photo_of_house !== null) ? customer.profile.photo_of_house : 'https://dummyimage.com/100x100/000/fff'}
                                            alt="pawame"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">Photo of house</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default Details;
