import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";

import { capitalCase } from 'change-case';

import { Icon } from '@iconify/react';
import infoFill from '@iconify/icons-eva/info-fill';


// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Stack,
    Divider,
    Container,
    Typography,
    Grid,
    Card, CardHeader, CardContent,
    Breadcrumbs,
    Tab, Tabs
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';
import KitDetailsView from 'src/components/_dashboard/inventory/kit/KitDetails';

// redux
import { getKit } from 'src/redux/slices/inventory/getKit';

// styles
const useStyles = makeStyles((theme) => ({
    root: {},
    tabBar: {
        zIndex: 9,
        bottom: 0,
        width: '100%',
        display: 'flex',
        position: 'absolute',

    }
}));

const KitDetails = () => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState('Details');

    const dispatch = useDispatch();
    const { kitQrCode } = useParams();
    const { kit } = useSelector((state) => state.kit);
    const [status] = useState('idle');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(getKit(kitQrCode));
        }

    }, [status, kitQrCode, dispatch]);

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const TABS = [
        {
            value: 'Details',
            icon: <Icon icon={infoFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="Details" />
                    <KitDetailsView kitDetails={kit}></KitDetailsView>
                </Card>
            )
        },
        {
            value: 'History log',
            icon: <Icon icon={infoFill} width={20} height={20} />,
            component: (
                <Card>
                    <CardHeader title="History log" />

                </Card>
            )
        }
    ];

    return (
        <Page title={`Invertory - Kit Details -  ${kitQrCode}`}>
            <Container>
                <Grid container spacing={2}>
                    {status === 'loading' ?
                        <Loader /> :
                        (<Grid item xs={12} sm={12} md={12}>
                            <Card
                                sx={{
                                    mb: 3,
                                    height: 150,
                                    position: 'relative'
                                }}
                            >

                                <div className={classes.tabBar}>
                                    <Tabs
                                        value={currentTab}
                                        scrollButtons="auto"
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                        onChange={handleChangeTab}
                                    >
                                        {TABS.map((tab) => (
                                            <Tab
                                                disableRipple
                                                key={tab.value}
                                                value={tab.value}
                                                icon={tab.icon}
                                                label={capitalCase(tab.value)}
                                            />
                                        ))}
                                    </Tabs>
                                </div>
                            </Card>

                            {TABS.map((tab) => {
                                const isMatched = tab.value === currentTab;
                                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                            })}
                        </Grid>)
                    }
                </Grid>

            </Container>
        </Page>
    )

};


export default KitDetails;