import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// ----------------------------------------------------------------------

// material
import {
    Box,
    Button,
    Stack,
    Dialog,
    DialogActions,
    Typography,
    DialogContent,
    Slide,
    Card,
    CardHeader,
    CardContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import NewBulkTokenDisbursementForm from './NewBulkTokenDisbursementForm';
// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewBulkTokenDisbursementDialog() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button size="medium" onClick={handleClickOpen} endIcon={<Icon icon={plusFill} />}>
                New bulk token disbursement
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullScreen={true}
                aria-describedby="alert-dialog-slide-description"
                style={{
                    marginTop: 50,
                    marginLeft: 100,
                    marginRight: 100,
                    marginBottom: 10
                }}
            >
                <DialogTitle>{"New token disbursement"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Fill form
                    </DialogContentText>
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                        <NewBulkTokenDisbursementForm />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
