import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Box,
    Stack,
    ListItem,
    ListItemText,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Typography,
    Divider,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { QRCodeSVG } from 'qrcode.react';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'code', label: 'Code', alignRight: false },
    { id: 'product', label: 'Product', alignRight: false },
    // { id: 'code', label: 'QR', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const getProductFromCode = (itemCode) => {
    // get 
    const productManufacturer = {
        "F": "Fosera",
        "B": "Biolite"
    };
    const productType = {
        "L": "Lamp",
        "B": "Battery",
        "S": "Solar Panel",
        "R": "Radio",
        "T": "Torch"
    };
    const pMan = (productManufacturer.hasOwnProperty(itemCode.charAt(0))) ? productManufacturer[itemCode.charAt(0)] : '';
    const pTyp = (productType.hasOwnProperty(itemCode.charAt(2))) ? productType[itemCode.charAt(2)] : '';
    return `${pMan} ${pTyp}`;
};

const ProductKit = ({ customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    return (
        <Scrollbar>
            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                    <ListItem key={customer.id} alignItems="flex-start" style={{ flexDirection: 'column' }}>
                        <ListItemText
                            style={{ margin: 5 }}
                            primary={customer?.parent_payment_plan}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        variant="body2"
                                        color="text.primary">
                                        {customer?.payment_plan}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                        <Divider />
                        <ListItemText
                            style={{ margin: 5 }}
                            primary={`First Kit ${customer?.first_compositeitem}`}
                            secondary={
                                <QRCodeSVG value={customer?.first_compositeitem} style={{ height: 100, width: 100, marginTop: 10 }} />
                            }
                        />
                        <Divider />
                        <ListItemText
                            style={{ margin: 5 }}
                            primary={`Last Kit ${customer?.latest_compositeitem}`}
                            secondary={
                                <QRCodeSVG value={customer?.latest_compositeitem} style={{ height: 100, width: 100, marginTop: 10 }} />
                            }
                        />
                    </ListItem>
                </Stack>
                <Stack spacing={3} width="50%" style={{ padding: 10 }}>
                    <TableContainer sx={{ minWidth: 200 }}>
                        <Table>
                            <TableHead sx={{}}>
                                <TableRow >
                                    {TABLE_HEAD.map((th_row) => {
                                        return (
                                            <TableCell>{th_row.label}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(customer?.customer_components) ? <>
                                    {(customer?.customer_components).split("|").map((item) => {
                                        return (
                                            <TableRow
                                                hover
                                                // key={item}
                                                tabIndex={-1}
                                            >
                                                <TableCell>{item}</TableCell>
                                                <TableCell>{getProductFromCode(item)}</TableCell>
                                                {/* <TableCell><QRCodeSVG value={item} style={{height: 30, width: 30}} /></TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                </> : <>
                                    No Items
                                </>}



                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
        </Scrollbar>
    );
}


export default ProductKit;
