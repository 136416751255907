import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Stocktake #', alignRight: false },
    { id: 'from_warehouse', label: 'From Warehouse', alignRight: false },
    { id: 'to_warehouse', label: 'To Warehouse', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'created_by', label: 'Created by', alignRight: false },
    { id: 'date', label: 'Dated', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const StockDispatchList = ({ }) => {
    const list = [];
    return (
        <Container>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 200 }}>
                    <Table>
                        <TableHead sx={{}}>
                            <TableRow >
                                {TABLE_HEAD.map((th_row) => {
                                    return (
                                        <TableCell>{th_row.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                    >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.warehouse}</TableCell>
                                        <TableCell>{row.warehouse}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.created_by?.email}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Container>

    );
}


export default StockDispatchList;
