import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import ClusterMoreMenu from './ClusterMoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'tags', label: 'Tags', alignRight: false },
    { id: 'tickets', label: 'Tickets count', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'actions', label: '', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const ClusterList = ({ clusters }) => {
    const list = (clusters !== null) ? clusters : [];
    console.log(list);
    return (
        <Container>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 200 }}>
                    <Table>
                        <TableHead sx={{}}>
                            <TableRow >
                                {TABLE_HEAD.map((th_row) => {
                                    return (
                                        <TableCell>{th_row.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                    >
                                        <TableCell>
                                            <RouterLink to={`/dashboard/clusters/cluster/${row.id}/detail/`} title={row.title}>{row.id}</RouterLink>
                                        </TableCell>
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.tags}</TableCell>
                                        <TableCell>{row.tickets_inventory.length}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell align="right">
                                            <ClusterMoreMenu row={row} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Container>

    );
}


export default ClusterList;
