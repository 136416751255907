
export const TABLE_HEAD_CLUSTER_TICKET_INVETORY = (user_group, exclude) => {
    var TABLE = [
        { id: 'ticket__id', label: 'Ticket number', alignRight: false },
        { id: 'ticket__title', label: 'Title', alignRight: false },
        { id: 'ticket__customer__full_name', label: 'Customer name', alignRight: false },
        { id: 'ticket__customer__mpesa_mobile', label: 'Customer mobile', alignRight: false },
        { id: 'ticket__date', label: 'Date', alignRight: false },
        { id: 'ticket__status', label: 'Status', alignRight: false },
        { id: 'ticket__product__name', label: 'Component sent', alignRight: false },
        { id: 'inventory_item__qr_code', label: 'Item qr code', alignRight: false, field: 'input:text' },
        { id: 'inventory_item__status', label: 'Item status', alignRight: false },
    ];
    if (exclude !== undefined) {
        exclude.map((x) => {
            TABLE = [...TABLE.filter((y) => y.id != x)];
        });
    }

    return TABLE;
}


export const TABLE_HEAD_CLUSTER_HISTORY = [
    { id: 'event', label: 'Event', alignRight: false },
    { id: 'timestamp', label: 'Timestamp', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
];