import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'mobile_number', label: 'Mobile Number', alignRight: false },
    { id: 'contact_origin', label: 'Contact Origin', alignRight: false },
    { id: 'date_created', label: 'Date Created', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const ContactList = ({ customerPhonebook }) => {
    const phoneNumbers = (customerPhonebook) ? customerPhonebook : [];
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {phoneNumbers.map((phoneNumber) => {
                            return (
                                <TableRow
                                    hover
                                    // key={phoneNumber.id}
                                    tabIndex={-1}
                                >
                                    <TableCell>{phoneNumber.mobile_number}</TableCell>
                                    <TableCell>{phoneNumber.contact_origin}</TableCell>
                                    <TableCell>{new Date(phoneNumber.date_created).toLocaleString()}</TableCell>
                                    <TableCell>{phoneNumber.notes}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default ContactList;
