import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import NewStockDispatchDialog from 'src/components/_dashboard/inventory/stock-dispatch/NewStockDispatchDialog';
import StockDispatchList from 'src/components/_dashboard/inventory/stock-dispatch/StockDispatchList';

const StockDispatchIndex = () => {
    
    return (
        <Page title="Stock dispatch">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Stock dispatch
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <NewStockDispatchDialog />
                            </Box>
                        </Stack>
                        <Card>
                            <StockDispatchList />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default StockDispatchIndex;