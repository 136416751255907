import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import NewStockReceiveDialog from 'src/components/_dashboard/inventory/stock-receive/NewStockReceiveDialog';
import StockReceiveList from 'src/components/_dashboard/inventory/stock-receive/StockReceiveList';

const StockReceiveIndex = () => {
    
    return (
        <Page title="Stock receive">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Stock receive
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <NewStockReceiveDialog />
                            </Box>
                        </Stack>
                        <Card>
                            <StockReceiveList />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default StockReceiveIndex;