import * as React from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
    Box,
    Card, CardContent, CardHeader,
    Grid,
    Container,
    Button,
    MenuItem,
    Stack,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// ----------------------------------------------------------------------

import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// components
import Page from 'src/components/Page';

// redux
import { sendSms } from 'src/redux/slices/sms/send';

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));

const NewSms = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        msisdn: Yup.string().required('Mobile no is required'),
        message: Yup.string().required('Composition is required'),
    });

    const formik = useFormik({
        initialValues: {
            msisdn: '',
            message: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const smsMessage = {
                    ...values,
                };
                sendSms(smsMessage).then((response) => {
                    if (response.status == 200) {
                        setSubmitting(false);
                        resetForm(true);
                        enqueueSnackbar('SMS sent', {
                            variant: 'success',
                            action: (key) => (
                                <Button size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </Button>
                            )
                        });
                    }
                }).catch(() => {

                }).finally(() => {

                })
            } catch (error) {
                console.log(error);
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('SMS sending failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {

        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;

    return (
        <Page title="New SMS">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                New SMS
                            </Typography>
                        </Stack>
                        <Card>
                            <CardHeader title="Fill form" />

                            <CardContent>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2, width: '100%' }}>
                                    <FormikProvider value={formik}>
                                        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '50%' }}>
                                            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                                <Stack spacing={3} width="100%">
                                                    <TextField
                                                        id='msisdn'
                                                        type='number'
                                                        label="Mobile No"
                                                        size="small"
                                                        {...getFieldProps('msisdn')}
                                                        error={Boolean(touched.msisdn && errors.msisdn)}
                                                        helperText={touched.msisdn && errors.msisdn}
                                                    />

                                                    <TextField
                                                        id="message"
                                                        label="Composition"
                                                        multiline
                                                        rows={4}
                                                        {...getFieldProps('message')}
                                                        error={Boolean(touched.message && errors.message)}
                                                        helperText={touched.message && errors.message}
                                                    />

                                                </Stack>
                                            </Box>

                                            <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                                <Button type='reset' color="error">Reset</Button>
                                                &nbsp;
                                                <LoadingButton
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    loading={isSubmitting}>
                                                    Send
                                                </LoadingButton>
                                            </Stack>
                                        </Form>
                                    </FormikProvider>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default NewSms;