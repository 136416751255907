import * as React from 'react';
import moment from 'moment';

// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'created_by', label: 'Executive', alignRight: false },
    { id: 'phone_number_used', label: 'Phone number', alignRight: false },
    { id: 'customer_available', label: 'Customer available', alignRight: false },
    // { id: 'system_usage_frequency', label: 'Sys usage', alignRight: false },
    // { id: 'system_used_for', label: 'Sys used for', alignRight: false },
    // { id: 'any_new_solar_system', label: 'Any new solay sys', alignRight: false },
    { id: 'created_by', label: 'Created by', alignRight: false },
    { id: 'date_created', label: 'Created on', alignRight: false },
    { id: 'offset_date', label: 'Date of offset', alignRight: false },
    { id: 'start_date_for_daily_payments', label: 'Start of daily payments', alignRight: false },
    { id: 'reason_for_not_paying', label: 'Why not paying', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false },
];


const CustomerSurveyList = ({ surveys }) => {
    return (
        // <Container>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 200 }}>
                    <Table>
                        <TableHead sx={{}}>
                            <TableRow >
                                {TABLE_HEAD.map((th_row) => {
                                    return (
                                        <TableCell>{th_row.label}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surveys.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                    >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.created_by.first_name} {row.created_by.last_name}</TableCell>
                                        <TableCell>{row.phone_number_used}</TableCell>
                                        <TableCell>{row.customer_available}</TableCell>
                                        {/* <TableCell>{row.system_usage_frequency}</TableCell> */}
                                        {/* <TableCell>{row.system_used_for}</TableCell> */}
                                        {/* <TableCell>{row.any_new_solar_system}</TableCell> */}
                                        <TableCell>{row.created_by?.email?.split(".")[0]}</TableCell>
                                        <TableCell>{moment(new Date(row?.date_created).toLocaleString()).format("YYYY-MM-DD")}</TableCell>
                                        <TableCell>
                                            {(row?.offset_date !== undefined) ? (moment(new Date(row?.offset_date).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(row?.offset_date).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                        </TableCell>
                                        <TableCell>
                                            {/* {moment(new Date(row?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD")} */}
                                            {(row?.start_date_for_daily_payments !== undefined) ? (moment(new Date(row?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(row?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                        </TableCell>
                                        <TableCell> {row?.reason_for_not_paying} </TableCell>
                                        <TableCell>{row.notes}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        // </Container>

    );
}


export default CustomerSurveyList;
