import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

// material
import {
    Button,
    Box,
    Card, CardContent,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

// components
import Page from 'src/components/Page';
import CustomerSurveyList from 'src/components/_dashboard/portfolio_health/CustomerSurveyList';
import { Loader } from 'src/components/common/Loader';

import useAuth from 'src/hooks/useAuth';

// redux
import { listSurveys } from 'src/redux/slices/customers/portfolioHealth';
import { addDays } from 'date-fns';

const PortfolioHealthSurveys = () => {
    const { user } = useAuth();

    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        listSurveys({ 
            created_by: localStorage.getItem('userId'),
            date_filter: moment(new Date()).format("YYYY-MM-DD") + '--' + moment(addDays(new Date(), 1)).format("YYYY-MM-DD"), 
            limit: 300, 
        }).then((response) => {
            if (response.status === 200) {
                setSurveys(response.data.results);
            }
        }).catch((error) => {

        })

    }, []);

    return (
        <Page title="Portfolio health - Customer surveys">
            {/* <Container> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Customer survey list <small>({surveys.length})</small>
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button size="medium" component={RouterLink} to={`/dashboard/portfolio-health/new-customer-survey/`} sx={{ color: 'text.secondary' }} endIcon={<Icon icon={plusFill} />}>
                                    New survey
                                </Button>
                            </Box>
                        </Stack>
                        {surveys.length < 0 ?
                            <Loader /> :
                            (<Card>

                                <CustomerSurveyList surveys={surveys} />
                            </Card>
                            )
                        }
                    </Grid>
                </Grid>
            {/* </Container> */}
        </Page>
    )

};


export default PortfolioHealthSurveys;