import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

export const searchCustomerAcross = createAsyncThunk('customer/searchCustomerAcross', async (params_) => {
    const response = await axios.get(`/v1/customers/customer/across/`, { params: params_ });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    customers: []
};

const slice = createSlice({
    name: 'customers_search_across',
    initialState,
    extraReducers: {
        [searchCustomerAcross.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [searchCustomerAcross.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.customers = action.payload;
            state.isLoading = false;
        },
        [searchCustomerAcross.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;