import * as React from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { makeStyles } from '@mui/styles';
// material
import {
    Box,
    Link,
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Button,
    MenuItem,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { createCustomerTicket } from 'src/redux/slices/tickets/createTicketSlice';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const NewContact = ({ customerDetails, handleSaveNewContact }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const customer = (customerDetails) ? customerDetails : {};

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        mobile_number: Yup.string().required('Mobile no is required'),
        origin: Yup.string().required('Origin is required'),
    });

    const formik = useFormik({
        initialValues: {
            mobile_number: '',
            origin: '',
            notes: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const newContact = {
                    ...values,
                    customer: customer.id
                };
                handleSaveNewContact(newContact);

            } catch (error) {
                console.log(error);
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Contact creation failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;


    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <Stack spacing={3} width="100%">
                        <TextField
                            id='mobile_number'
                            type='text'
                            label="Mobile No"
                            size="small"
                            {...getFieldProps('mobile_number')}
                            error={Boolean(touched.mobile_number && errors.mobile_number)}
                            helperText={touched.mobile_number && errors.mobile_number}
                        />

                        <TextField
                            id="origin"
                            select
                            label="Origin"
                            size="small"
                            {...getFieldProps('origin')}
                            error={Boolean(touched.origin && errors.origin)}
                            helperText={touched.origin && errors.origin}
                        >
                            {(['3cx', 'default', 'lumeter', 'paygops']).map((origin) => (
                                <MenuItem key={origin} value={origin}>
                                    {origin}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="notes"
                            label="Notes"
                            multiline
                            rows={4}
                            {...getFieldProps('notes')}
                            error={Boolean(touched.notes && errors.notes)}
                            helperText={touched.notes && errors.notes}
                        />

                    </Stack>
                </Box>

                <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                    <Button type='reset' color="error">Reset</Button>
                    <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}>
                        Save contact
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}


export default NewContact;
