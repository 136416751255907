import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import StocktaleList from 'src/components/_dashboard/inventory/stocktake/StocktakeList';
import NewStocktakeDialog from 'src/components/_dashboard/inventory/stocktake/NewStocktakeDialog';

const StocktakeIndex = () => {

    return (
        <Page title="Stocktake">
            <Container>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Stocktake
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <NewStocktakeDialog />
                            </Box>
                        </Stack>
                        <Card>
                            <StocktaleList />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default StocktakeIndex;