import * as React from 'react';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { ExportJsonCsv } from 'react-export-json-csv';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Card, CardHeader, CardContent,
    Stack,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// componets
import Scrollbar from 'src/components/Scrollbar';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import cloudFill from '@iconify/icons-eva/cloud-upload-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// ----------------------------------------------------------------------

// utils
import { excelToJson } from 'src/utils/excelToJson';
// ----------------------------------------------------------------------

//  redux
import { getWarehouse } from 'src/redux/slices/inventory/getWareHouse';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const NewStocktakeForm = () => {

    const dispatch = useDispatch();
    const { warehouses, status } = useSelector(state => state.warehouse);

    const [items, setItems] = useState([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        date: Yup.string().required('Date is required'),
        warehouse: Yup.string().required('Warehouse is required'),
    });

    const formik = useFormik({
        initialValues: {
            date: '',
            warehouse: '',
            description: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                console.log(items);
                if (items.length <= 0) {
                    var error = { code: 100, message: 'Missing items' };
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Stocktake upload failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }else{
                    const newStocktake = {
                        ...values,
                        items
                    };
                    // handleSaveNewContact(newContact);
                    resetForm();
                    setItems([]);
                }
            } catch (error) {
                console.log(error);
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Stocktake upload failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;

    const handleFileUpload = (e) => {
        console.log(e);
        // excelToJson(e, (data) => {
        //     console.log(data);
        //     setItems(data);
        // });
    }


    useEffect(() => {
        if (status === 'idle') {
            dispatch(getWarehouse());
        }

    }, [status, dispatch]);

    return (
        <Scrollbar>
            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2 }}>
                <Stack spacing={3} width="35%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="New" />
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <Stack spacing={3} width="100%">

                                            <TextField
                                                id="date"
                                                label="Date"
                                                type="date"
                                                size="small"
                                                // value={initialValues.date}
                                                // defaultValue={initialValues.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...getFieldProps('date')}
                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                            />

                                            <TextField
                                                id="warehouse"
                                                select
                                                label="Warehouse"
                                                size="small"
                                                {...getFieldProps('warehouse')}
                                                error={Boolean(touched.origin && errors.origin)}
                                                helperText={touched.origin && errors.origin}
                                            >
                                                {/* {(warehouses).map((warehouse) => (
                                                    <MenuItem key={warehouse.id} value={warehouse.id}>
                                                        {warehouse.name}
                                                    </MenuItem>
                                                ))} */}
                                            </TextField>

                                            <TextField
                                                id="description"
                                                label="Description"
                                                multiline
                                                rows={3}
                                                {...getFieldProps('description')}
                                                error={Boolean(touched.description && errors.description)}
                                                helperText={touched.description && errors.description}
                                            />

                                            <Button
                                                variant='contained'
                                                component='label'
                                                color="info"
                                                startIcon={<Icon icon={cloudFill} />}
                                            >
                                                Upload Item Codes
                                                <input
                                                    name='avatar'
                                                    accept='xlsx,xls'
                                                    id='contained-button-file'
                                                    type='file'
                                                    hidden
                                                    placeholder='Upload kit items'
                                                    onChange={(e) => {
                                                        handleFileUpload(e)
                                                    }}
                                                />
                                            </Button>
                                        </Stack>
                                    </Box>

                                    <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                        <Button type='reset' color="error">Reset</Button>
                                        <LoadingButton
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}>
                                            Upload and save
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Stack>

                <Stack spacing={3} width="60%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="Template / Upload content" />
                        <CardContent>
                            <TableContainer sx={{ minWidth: 200, }}>
                                <Table>
                                    <TableHead sx={{}}>
                                        <TableRow >
                                            {[{ key: 'ItemCode', name: 'ItemCode' }].map((th_row) => {
                                                return (
                                                    <TableCell>{th_row.name}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.code}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell>{row.ItemCode}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ExportJsonCsv
                                style={{
                                    marginTop: 8,
                                    backgroundColor: "#00AB55", color: "white", cursor: "pointer",
                                    padding: 5, borderRadius: 4,
                                    width: 'auto'
                                }}
                                fileTitle={'stocktake-template'}
                                headers={[{ key: 'ItemCode', name: 'ItemCode' },]}
                                items={[{ id: 'FOV402800795', ItemCode: 'FOV402800795' }]}>
                                Export
                            </ExportJsonCsv>
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

        </Scrollbar>
    );
}


export default NewStocktakeForm;
