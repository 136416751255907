import { styled } from '@mui/system';
import { useSharedStyles } from "./SharedStyles";


const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

const Root = styled('div')(
    ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
  }
  `,
);


const TicketIssue = ({ ticket }) => {
    const classes = useSharedStyles();
    return (
        <Root sx={{ width: 500, maxWidth: '100%' }}>
            <table aria-label="custom pagination table">
                <tbody>
                    <tr>
                        <td className={classes.tableTitle}>Product</td>
                        <td align="right">
                            {ticket?.product?.name}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>Description</td>
                        <td align="right">
                            {ticket?.description}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>Resolution</td>
                        <td align="right">
                            {ticket?.resolution}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle}>Created By</td>
                        <td align="right">
                            {ticket?.created_by?.username}
                        </td>
                    </tr>

                </tbody>
            </table>
        </Root>
    );
}

export default TicketIssue;
