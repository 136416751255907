import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Link as RouterLink } from 'react-router-dom';

import { capitalCase } from 'change-case';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Scrollbar from 'src/components/Scrollbar';
import { Loader } from 'src/components/common/Loader';

import { getKits } from 'src/redux/slices/inventory/getKits';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'id', alignRight: false },
    { id: 'name', label: 'name', alignRight: false },
    // { id: 'upgraded', label: 'upgraded', alignRight: false },
    // { id: 'location_description', label: 'location_description', alignRight: false },
    { id: 'status', label: 'status', alignRight: false },
    { id: 'date_of_status', label: 'date_of_status', alignRight: false },
    { id: 'state', label: 'state', alignRight: false },
    { id: 'date_of_state', label: 'date_of_state', alignRight: false },
    { id: 'shop', label: 'shop', alignRight: false },
    { id: 'actions', label: '', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const WarehouseInventory = ({ warehouseDetails }) => {

    const warehouse = (warehouseDetails) ? warehouseDetails : {};

    const dispatch = useDispatch();
    const { kits } = useSelector((state) => state.kits);
    const [status] = useState('idle');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(getKits({ warehouse: warehouse.id, limit: 50 }));
        }

    }, [status, dispatch]);

    return (
        <Container>
            <Scrollbar>
                {status === 'loading' ?
                    <Loader /> :
                    (<TableContainer sx={{ minWidth: 200 }}>
                        <Table>
                            <TableHead sx={{}}>
                                <TableRow >
                                    {TABLE_HEAD.map((th_row) => {
                                        return (
                                            <TableCell>{capitalCase((th_row.label).replace('-', ' '))}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kits.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            tabIndex={-1}
                                        >
                                            <TableCell>
                                                <RouterLink to={`/dashboard/inventory/kit/${row.qr_code}/`} title={row.qr_code}>{row.qr_code}</RouterLink>
                                            </TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            {/* <TableCell>{row.upgraded}</TableCell> */}
                                            {/* <TableCell>{row.location_description}</TableCell> */}
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.date_of_status}</TableCell>
                                            <TableCell>{row.state}</TableCell>
                                            <TableCell>{row.date_of_state}</TableCell>
                                            <TableCell>{row.shop}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>)
                }
            </Scrollbar>
        </Container>

    );
}


export default WarehouseInventory;
