import { styled } from '@mui/system';

// ---------------
import { useSharedStyles } from "./SharedStyles";





const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    1500: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

const Root = styled('div')(
    ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td{
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }
  `,
);


const DetailsTable = ({ ticket }) => {
    const classes = useSharedStyles();

    return (
        <Root sx={{ width: 500, maxWidth: '100%' }}>
            <table aria-label="custom pagination table">
                <thead>
                    <tr>
                        <th className={classes.tableHead}>Info</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={classes.tableTitle} >ID</td>
                        <td align="right">
                            {ticket?.id}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Title</td>
                        <td align="right">
                            {ticket?.title}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Customer</td>
                        <td align="right">
                            {ticket?.customer?.full_name}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Date</td>
                        <td align="right">
                            {ticket?.date}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Category</td>
                        <td align="right">
                            {ticket?.category?.name}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.tableTitle} >Account</td>
                        <td align="right">
                            {ticket?.account}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Calling in Phone Number</td>
                        <td align="right">
                            {ticket?.call_in_phone_number}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.tableTitle} >Priority</td>
                        <td align="right">
                            {ticket?.priority}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.tableTitle} >Resolved By</td>
                        <td align="right">
                            {ticket?.resolve_by}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Date Resolved</td>
                        <td align="right">
                            {ticket?.date_resolved}
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.tableTitle} >Status</td>
                        <td align="right">
                            {ticket?.status}
                        </td>
                    </tr>


                </tbody>
            </table>
        </Root>
    );
}

export default DetailsTable;
