import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getTickets = createAsyncThunk('tickets/getTickets', async () => {
    const response = await axios.get(`/v1/tickets/ticket`, { params: { limit: 50 } });
    console.log(response);
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    data: {
        count: null,
        next: null,
        previous: null,
        results: []
    }
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    extraReducers: {
        [getTickets.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getTickets.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
            state.isLoading = false;
        },
        [getTickets.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            console.log(action.payload);
            state.isLoading = false;
        },

    }
});

// Actions
// ---------------

// Reducer
export default slice.reducer;