import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { filter } from 'lodash';

// material
import {
    Button,
    Box,
    Card, CardHeader, CardMedia, CardContent, CardActions,
    List, ListItem, ListItemText,
    Grid,
    Container,
    Stack,
    Typography,
    OutlinedInput,
    Divider,
    Table, TableHead, TableRow, TableBody, TableCell, TableContainer,
} from '@mui/material';
import { styled } from '@mui/material/styles';


// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';


// redux
// import { searchCustomerAcross } from 'src/redux/slices/customers/searchCustomerAcross';
import { searchCustomerAcrossCall } from 'src/redux/slices/customers/portfolioHealth';
import { listTickets } from 'src/redux/slices/portfolio_health/tickets';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


const TicketList = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const [status, setStatus] = useState('loading');
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        setStatus('loading')
        listTickets({}).then((response) => {
            setTickets(response.data.results);

            setStatus('done');
        }).catch((error) => {
            enqueueSnackbar('Error loading tickets', {
                variant: 'error',
                action: (key) => (
                    <Button size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={error} />
                    </Button>
                )
            });
        })
    }, [dispatch]);


    return (
        <Page title="Ticket list">
            <Grid container spacing={2} sx={{ maxWidth: '100%', margin: 2 }}>
                {status === 'loading' ?
                    <Loader sx={{ maxWidth: '100%', margin: 2 }} />
                    :
                    (<>
                        {(tickets !== undefined && tickets.length > 0) ?

                            <TableContainer sx={{ minWidth: 200 }}>
                                <Table>
                                    <TableHead sx={{}}>
                                        <TableRow >
                                            {["ID", "Title", "Description", "Status", "data"].map((th_row) => {
                                                return (
                                                    <TableCell>{th_row}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {tickets.map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell>{row.id}</TableCell>
                                                    <TableCell>{row.title}</TableCell>
                                                    <TableCell>{row.description}</TableCell>
                                                    <TableCell>{row.status}</TableCell>
                                                    <TableCell>{row.date}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <div>
                                No data found
                            </div>
                        }
                    </>)}
            </Grid>
        </Page>
    )
};


export default TicketList;