import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import {
    Box,
    Card,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import NewBulkTokenDisbursementDialog from 'src/components/_dashboard/portfolio_health/promos/NewBulkTokenDisbursementDialog';
import NewTokenDisbursementDialog from 'src/components/_dashboard/portfolio_health/promos/NewTokenDisbursementDialog';
import TokenDisbursementList from 'src/components/_dashboard/portfolio_health/promos/TokenDisbursementList';

const PromoTokenDisbursementsIndex = () => {

    return (
        <Page title="Token disbursement">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Token disbursement
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <NewBulkTokenDisbursementDialog />
                                <NewTokenDisbursementDialog />
                            </Box>
                        </Stack>
                        <Card>
                            <TokenDisbursementList list={[]} />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )

};


export default PromoTokenDisbursementsIndex;