
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { priorities, ticketStatus, titles, components, surveyQuesIO } from "src/defaults";

import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Button,
    MenuItem,
    Chip,
    Typography
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { getItemCategory } from 'src/redux/slices/tickets/getItemCategory';
import { createCustomerTicket } from 'src/redux/slices/tickets/createTicketSlice';

// ----------------------------------------------------------------------


const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const CreateTicketForm = ({ accountId, callingNumber }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user } = useSelector(state => state.authJwt);

    const isMountedRef = useIsMountedRef();

    

    const Formchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        call_in_phone_number: Yup.string().required('Call in phone number is required'),
        priority: Yup.string().required('Priority is required'),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            component: '',
            call_in_phone_number: callingNumber,
            status: 'open',
            priority: 'normal',
            description: '',
            resolution: '',
            tags: [callingNumber],
            product_in_household: '',
            product_working: '',
            scheduled_visit: '',
            created_by: user
        },
        validationSchema: Formchema,
        enableReinitialize: true,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const customerTicket = {
                    ...values,
                    associated_account: accountId
                }

                const result = await createCustomerTicket(customerTicket);

                if (result) {
                    enqueueSnackbar('Ticket Created Successfully', {
                        variant: 'success',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                    setSubmitting(false);
                    resetForm(true);
                    navigate('/dashboard/tickets', { replace: true });
                } else {
                    enqueueSnackbar('Ticket Creation failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                    setSubmitting(false);
                }

            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Ticket Creation Failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }

            }

        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;


    const fomartKits = (kits = '') => {
        const allKits = kits.split('|');
        return allKits;
    };

    const [ component_io, setComponentio ] = useState(false);

    useEffect(() => {
        setComponentio((values.title.toLowerCase() === 'product malfunction'));
        formik.setFieldValue('product', (values.title.toLowerCase() === 'product malfunction') ? values.product : '');
    }, [values, dispatch]);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <Stack spacing={3} width="50%">

                        <TextField
                            id='call_in_phone_number'
                            type='text'
                            label="Phone number used to call"
                            disabled
                            size="small"
                            value={callingNumber}
                            {...getFieldProps('call_in_phone_number')}
                            error={Boolean(touched.call_in_phone_number && errors.call_in_phone_number)}
                            helperText={touched.call_in_phone_number && errors.call_in_phone_number}
                        />


                        <TextField
                            id="title"
                            select
                            size="small"
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                        >
                            {titles.map((title, i) => (
                                <MenuItem key={i + 1} value={title.name}>
                                    {title.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="component"
                            select
                            size="small"
                            label="Component"
                            {...getFieldProps('component')}
                            error={Boolean(touched.component && errors.component)}
                            helperText={touched.component && errors.component}
                            disabled={!component_io}
                        >
                            {components.map((component, i) => (
                                <MenuItem key={i + 1} value={component.name}>
                                    {component.name}
                                </MenuItem>
                            ))}
                        </TextField>



                    </Stack>
                </Box>

                <Box sx={{ display: 'flex', marginTop: '0.5em', flexDirection: "column", justifyContent: "space-between" }}>

                    <Stack spacing={3} width="50%">

                        <TextField
                            id="description"
                            label="Description"
                            multiline
                            rows={4}
                            size="small"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                        />

                        <TextField
                            id="resolution"
                            label="Resolution"
                            multiline
                            rows={4}
                            {...getFieldProps('resolution')}
                            error={Boolean(touched.resolution && errors.resolution)}
                            helperText={touched.resolution && errors.resolution}
                        />

                        <TextField
                            id="status"
                            select
                            label="Status"
                            size="small"
                            {...getFieldProps('status')}
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                        >
                            {ticketStatus.map((option, i) => (
                                <MenuItem key={i + 1} value={option.id} selected={( option.id === 'open')}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="priority"
                            select
                            label="Priority"
                            size="small"
                            {...getFieldProps('priority')}
                            error={Boolean(touched.priority && errors.priority)}
                            helperText={touched.priority && errors.priority}
                        >
                            {priorities.map((option, i) => (
                                <MenuItem key={i + 1} value={option.id} selected={( option.id === 'normal')}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="product_in_household"
                            select
                            label="Is product in house hold"
                            size="small"
                            {...getFieldProps('product_in_household')}
                            error={Boolean(touched.product_in_household && errors.product_in_household)}
                            helperText={touched.product_in_household && errors.product_in_household}
                        >
                            {surveyQuesIO.map((option, i) => (
                                <MenuItem key={i + 1} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="product_working"
                            select
                            label="Is product wokring"
                            size="small"
                            {...getFieldProps('product_working')}
                            error={Boolean(touched.product_working && errors.product_working)}
                            helperText={touched.product_working && errors.product_working}
                        >
                            {surveyQuesIO.map((option, i) => (
                                <MenuItem key={i + 1} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="scheduled_visit"
                            select
                            label="Is visit scheduled"
                            size="small"
                            {...getFieldProps('scheduled_visit')}
                            error={Boolean(touched.scheduled_visit && errors.scheduled_visit)}
                            helperText={touched.scheduled_visit && errors.scheduled_visit}
                        >
                            {surveyQuesIO.map((option, i) => (
                                <MenuItem key={i + 1} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Stack>
                </Box>

                <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                    <Button type='reset' color="error">Reset</Button>
                    <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Create Ticket
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}


export default CreateTicketForm;
