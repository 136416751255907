import * as React from 'react';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { ExportJsonCsv } from 'react-export-json-csv';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import { makeStyles } from '@mui/styles';
import {
    Box,
    Card, CardHeader, CardContent,
    Stack,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    MenuItem,
    Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// componets
import Scrollbar from 'src/components/Scrollbar';
// ----------------------------------------------------------------------

// icons
import { Icon } from '@iconify/react';
import cloudFill from '@iconify/icons-eva/cloud-upload-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// ----------------------------------------------------------------------

// utils
import { excelToJson } from 'src/utils/excelToJson';
import { TABLE_HEAD_CLUSTER_TICKET_INVETORY } from './_const';

// ----------------------------------------------------------------------

//  redux
import { addCluster } from 'src/redux/slices/clusters/addCluster';
import { previewCluster } from 'src/redux/slices/clusters/addCluster';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const NewClusterForm = () => {

    const navigate = useNavigate();

    const [tickets, setTickets] = useState([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: ''
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                if (tickets.length <= 0) {
                    var error = { code: 100, message: 'Missing tickets' };
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Cluster creation failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                } else {
                    var tags = ['created'];
                    var tickets_ = tickets.map(function (row) { return row.id; });
                    const newCluster = {
                        ...values,
                        tickets: tickets_,
                        tags
                    };
                    addCluster(newCluster).then((response) => {
                        if (response.status === 201) {
                            resetForm();
                            setTickets([]);
                            navigate(`/dashboard/clusters/cluster/${response.data.id}/detail/`, { replace: true });
                        }
                    }).catch(() => {

                    }).finally(() => {

                    });
                }
            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('New cluster upload failed', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {

        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps
    } = formik;

    const handleFileUpload = (e) => {
        excelToJson(e, (data) => {
            var tickets = data.map(function (row) { return row.id; });
            // preview cluster
            previewCluster({ tickets }).then((response) => {
                if (response.status == 200) {
                    // set tickets
                    setTickets(response.data.tickets);
                }
            }).catch(() => {

            }).finally(() => {

            })
        });
    }

    useEffect(() => {

    }, []);

    return (
        <Scrollbar>
            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", margin: 2 }}>
                <Stack spacing={3} width="35%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="Fill form" />
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <Stack spacing={3} width="100%">

                                            <TextField
                                                id="title"
                                                label="Title"
                                                size="small"
                                                {...getFieldProps('title')}
                                                error={Boolean(touched.title && errors.title)}
                                                helperText={touched.title && errors.title}
                                            />

                                            <TextField
                                                id="description"
                                                label="Description"
                                                multiline
                                                rows={3}
                                                {...getFieldProps('description')}
                                                error={Boolean(touched.description && errors.description)}
                                                helperText={touched.description && errors.description}
                                            />

                                            <Button
                                                variant='contained'
                                                component='label'
                                                color="info"
                                                startIcon={<Icon icon={cloudFill} />}
                                            >
                                                Upload tickets
                                                <input
                                                    name='avatar'
                                                    accept='xlsx,xls'
                                                    id='contained-button-file'
                                                    type='file'
                                                    hidden
                                                    placeholder='Upload kit items'
                                                    onChange={(e) => {
                                                        handleFileUpload(e)
                                                    }}
                                                />
                                            </Button>
                                        </Stack>
                                    </Box>
                                    <Divider/>
                                    <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                        <Button type='reset' color="error">Reset</Button>
                                        <LoadingButton
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}>
                                            Upload and save
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Stack>

                <Stack spacing={3} width="60%" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader title="Tickets" />
                        <CardContent>
                            <TableContainer sx={{ minWidth: 200, }}>
                                <Table>
                                    <TableHead sx={{}}>
                                        <TableRow >
                                            {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, ['item_qrcode']).map((th_row) => {
                                                return (
                                                    <TableCell>{th_row.label}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    {(tickets.length > 0 ?
                                        <TableBody>
                                            {tickets.map((ticket) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={ticket.id}
                                                        tabIndex={-1}
                                                    >
                                                        {TABLE_HEAD_CLUSTER_TICKET_INVETORY(undefined, ['item_qrcode']).map((th_row) => {
                                                            return (
                                                                <TableCell>{ticket[th_row.id]}</TableCell>
                                                            )
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        :
                                        <></>
                                    )}

                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

        </Scrollbar>
    );
}


export default NewClusterForm;
