import * as React from 'react';
import * as Yup from 'yup';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    Card, CardHeader, CardContent,
    Divider,
    Stack,
    TextField,
    Button,
    Container,
    Grid,
    List, ListItem, ListItemText,
    DialogContent, DialogContentText, DialogTitle, Dialog, DialogActions,
    Slide,
    OutlinedInput,
    InputAdornment,
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer,
    MenuItem,
    ListSubheader
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';

// redux
import { searchCustomer, addCustomerPromotionOfferChangeSurvey, changeOffer } from 'src/redux/slices/customers/portfolioHealth';
import { getSurveyQuestions } from 'src/redux/slices/portfolio_health/getSurveyQuestions';

import Label from 'src/components/Label';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const PortfolioHealthOfferChange = () => {

    const [customer, setCustomer] = useState();
    const [account, setAccount] = useState(undefined);

    const org_options = [
        { k: 'none', v: 'Choose organization' },
        { k: 'pawame', v: 'pawame' }];
    const org_codes = { 'none': 'none', 'pawame': 'pawame', 'mwezi limited': 'OR000081', 'mwezi solar limited': 'OR001014', 'ignite solrm': 'solrm' };
    const [customer_available_options, set_customer_available_options] = useState([]);
    const [ready, set_customer_selection_ready] = useState(false);
    const [token_response, set_token_response] = useState('');
    const [customer_owns_kit_options, set_customer_owns_kit_options] = useState([]);
    const [customer_near_the_kit_options, set_customer_near_the_kit_options] = useState([]);

    const [org_id, setOrgId] = useState('none');

    const [isChanging, setIsChanging] = useState(false);

    const [customer_available_io, set_customer_available_io] = useState(false);
    const [customer_near_device_io, set_customer_near_device_io] = useState(true);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        phone_number_used: Yup.string().required('Field is required'),
        customer_available: Yup.string().required('Field is required'),
        customer_owns_kit: Yup.string().required('Field is required'),
        current_phone_number: Yup.string().required('Field is required'),
    });

    const formik = useFormik({
        initialValues: {
            phone_number_used: '',
            customer_available: '',

            customer_owns_kit: '',
            customer_near_the_kit: '',
            current_phone_number: '',
            alternative_phone_numbers: '',

            notes: '',
            token_response: '',

            org_id: ''
        },
        validationSchema: FormSchema,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const newSurvey = {
                    ...values
                };
                if (org_codes[org_id] === 'pawame') {
                    newSurvey['account'] = account.id;
                }
                else if (org_codes[org_id] === 'solrm') {
                    newSurvey['solrm_account'] = account.id;
                }
                else {
                    newSurvey['associated_account'] = account.id;
                }
                addCustomerPromotionOfferChangeSurvey(newSurvey).then((response) => {
                    if (response.status === 201) {
                        resetForm();
                        setAccount(undefined);
                        setCustomer(undefined);
                        onOrgChange('none');
                        enqueueSnackbar('Customer survey form saved', {
                            variant: 'success',
                            action: (key) => (
                                <Button size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </Button>
                            )
                        });
                    } else {
                        setErrors({ afterSubmit: response.data.details });
                    }
                }).catch((error) => {
                    setErrors({ afterSubmit: 'Error submitting' });
                });
            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Error saving', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps,
    } = formik;


    const handleAccountChoose = (row) => {
        setCustomer(row.customer);
        setAccount(row.account);
    };

    const onOrgChange = (value) => {
        setOrgId(value);
        set_customer_selection_ready((value === 'none') ? false : true);
        formik.resetForm();
        setAccount(null);
        setCustomer(null);
    };

    const handleChangeOffer = () => {

        var req = window.confirm("Please confirm change of offer");
        if (req == true) {
            setIsChanging(true);
            var payLoad = {
                'account': account.id,
                'sms_phone_number': formik.values['current_phone_number']
            };
            changeOffer(payLoad).then((response) => {
                if (response.status == 200) {
                    alert(response.data.res);
                    set_token_response(response.data.res);
                }
                enqueueSnackbar('Request made', {
                    variant: (response.status == 200) ? 'success' : 'error',
                    action: (key) => (
                        <Button size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </Button>
                    )
                });
            }).catch((error) => {
                enqueueSnackbar('Error in request', {
                    variant: 'error',
                    action: (key) => (
                        <Button size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </Button>
                    )
                });
            }).finally(() => {
                setIsChanging(false);
            });
        } else {
            enqueueSnackbar('Request cancelled', {
                variant: 'error',
                action: (key) => (
                    <Button size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </Button>
                )
            });
        }
    };

    const dispatch = useDispatch();


    const { questions, status } = useSelector(state => state.questions);


    useEffect(() => {

        if (status === 'idle') {
            dispatch(getSurveyQuestions());
        }

        if (questions.length > 0) {
            set_customer_available_options(questions.filter((question_) => question_.question_key == 'customer_available')[0].answers);
            set_customer_owns_kit_options(questions.filter((question_) => question_.question_key == 'customer_owns_kit')[0].answers);
            set_customer_near_the_kit_options(questions.filter((question_) => question_.question_key == 'customer_near_the_kit')[0].answers);
        }

        set_customer_available_io((values.customer_available == 'Available' && account !== undefined));
        set_customer_near_device_io((values.customer_near_the_kit == 'Yes'))
    }, [values, account, status, dispatch]);

    const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
        width: 240,
        transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`
        }
    }));



    const SearchCustomerDialog = ({ handleAccountChoose, ready, org_id }) => {

        const [busyPlaceholder, setBusyPlaceholder] = useState('No records');

        const [filterName, setFilterName] = useState('');

        const [open, setOpen] = React.useState(false);

        const [rows, setRows] = useState([]);

        useEffect(() => {

        }, []);


        const handleClickOpen = () => {
            setOpen(true);
            setFilterName('');
            setBusyPlaceholder('');
            setRows([]);
        };

        const handleClose = () => {
            setOpen(false);
            setFilterName('');
            setBusyPlaceholder('');
            setRows([]);
        };


        const handleCardClick = (row) => {
            handleAccountChoose(row);
            setOpen(false);
            setFilterName('');
            setRows([]);
        };


        const handleKeypress = (event) => {

            if (event.which === 13) {
                // check if enough characters
                if (filterName.length < 8) {
                    enqueueSnackbar('Search term is short.  Enter more than 8', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                    return;
                } else {
                    setBusyPlaceholder(`Searching ${filterName} in ${org_id}...`)
                    searchCustomer(filterName, org_codes[org_id]).then((response) => {
                        if (response.status === 200) {
                            setRows(response.data.results);
                            setBusyPlaceholder(`${response.data.results.length} customers found for ${filterName} in ${org_id}.`)
                        } else {
                            setBusyPlaceholder(`No customers found for ${filterName} in ${org_id}.`)
                        }
                    }).catch(() => {
                        setBusyPlaceholder(`No customers found for ${filterName} in ${org_id}.`)
                    });
                }

            }
        };

        const handleChange = (event) => {
            setFilterName(event.target.value);
            setRows([]);
        };


        return (
            <div style={{ width: '100%' }}>
                {ready === false ?
                    (<></>) :
                    (<>
                        <Button size="medium" onClick={handleClickOpen} endIcon={<Icon icon={searchFill} />}>
                            Search customer
                        </Button>

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            fullScreen={true}
                            aria-describedby="alert-dialog-slide-description"
                            style={{
                                marginTop: 10,
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 10
                            }}
                        >
                            <DialogTitle>{`Search customer in ${org_id}`}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Enter phone number or id (Press enter to search). Click on the card to choose
                                </DialogContentText>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                                    <Stack spacing={3} width="100%" style={{ padding: 10 }}>
                                        <SearchStyle
                                            size='small'
                                            value={filterName}
                                            onChange={handleChange}
                                            onKeyPress={handleKeypress}
                                            placeholder={'Search customer by mpesa, phone no or nationa id'}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            }
                                        />
                                        <Divider />
                                        <Grid container spacing={2}>
                                            {rows.length > 0 ?
                                                (<>
                                                    {rows.map((row) => {
                                                        return <div>
                                                            <Card sx={{ maxWidth: '100%', padding: 2, margin: 2 }} >
                                                                <CardHeader
                                                                    sx={{ textDecoration: 'none' }}
                                                                    title={row.full_name}
                                                                    subheader={`Mobile: ${row.mobile_number} Mpesa: ${row.mpesa_mobile}`}
                                                                />
                                                                <CardContent>
                                                                    <List sx={{ width: '100%', maxWidth: 550, bgcolor: 'background.paper' }} >
                                                                        {
                                                                            row.account.map((account => (
                                                                                <>
                                                                                    <ListItem key={account.id} alignItems="flex-start">
                                                                                        <ListItemText
                                                                                            primary={account.account_no}
                                                                                            key={account.id}
                                                                                            secondary={
                                                                                                <React.Fragment>
                                                                                                    <Typography variant="body2" color="text.primary">
                                                                                                        {account.payment_plan}
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                                        {account.account_status}
                                                                                                    </Typography>
                                                                                                    <Button color="primary" onClick={() => { handleCardClick({ customer: row, account: account }) }}>
                                                                                                        Choose
                                                                                                    </Button>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        />
                                                                                    </ListItem>
                                                                                </>
                                                                            )))
                                                                        }
                                                                    </List >
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    })}
                                                </>) :
                                                (<>{busyPlaceholder}</>)}
                                        </Grid>
                                    </Stack>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color="error" onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </>)}
            </div>
        )
    }

    return (
        <Page title="Portfolio health - New survey">
            {/* <Container> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {`Customer porfolio health`}
                        </Typography>
                    </Stack>
                    {status === 'loading' ?
                        <Loader /> :
                        (<>
                            <Card>
                                <CardHeader title={`New promotion offer change survey`} />
                                <CardContent>
                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <TextField
                                            id="org_id"
                                            label="Organization"
                                            size="small"
                                            onChange={e => onOrgChange(e.target.value)}
                                            value={org_id}
                                            select
                                        >
                                            {(org_options).map((option) => (
                                                <MenuItem key={option.k} value={option.v} disabled={(option.k === 'none')} selected={(org_id === option.k)} >
                                                    {option.v.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Divider />
                                    &nbsp;
                                    &nbsp;

                                    <SearchCustomerDialog handleAccountChoose={handleAccountChoose} ready={ready} org_id={org_id} />
                                    &nbsp;
                                    &nbsp;


                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "row", justifyContent: "flex-start" }}>
                                        <Grid item xs={12} sm={12} md={5} sx={{ mt: 1, display: 'flex', flexDirection: "column" }}>
                                            <Divider />
                                            <TableContainer sx={{ minWidth: 200 }}>
                                                <Table size='small'>
                                                    <caption>Customer and loan info</caption>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Names</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.full_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Plan</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.payment_plan}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Phone</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.mpesa_mobile}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Loan #</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.account_no}
                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Location</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.region?.county?.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Balance</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.payoffamount} &nbsp; &nbsp;<small><code>Might be incorrect</code></small>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>

                                                            <TableCell>
                                                                <strong>Last payment date</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {(account?.last_payment_datetime !== undefined) ? (moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Box>

                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                                        <Label variant="h6">
                                            Fill form
                                        </Label>
                                        <FormikProvider value={formik}>
                                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>

                                                    {/* Common survey form starts here */}
                                                    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="phone_number_used"
                                                                label="Phone number used to call"
                                                                size="small"
                                                                {...getFieldProps('phone_number_used')}
                                                                error={Boolean(touched.phone_number_used && errors.phone_number_used)}
                                                                helperText={touched.phone_number_used && errors.phone_number_used}
                                                            ></TextField>
                                                        </Box>
                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="customer_available"
                                                                label="Customer available"
                                                                size="small"
                                                                {...getFieldProps('customer_available')}
                                                                error={Boolean(touched.customer_available && errors.customer_available)}
                                                                helperText={touched.customer_available && errors.customer_available}
                                                                select
                                                            >
                                                                {(customer_available_options).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="customer_owns_kit"
                                                                label="Does the customer own the kit?"
                                                                size="small"
                                                                {...getFieldProps('customer_owns_kit')}
                                                                disabled={!customer_available_io}
                                                                select
                                                            >
                                                                {(customer_owns_kit_options).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Box>


                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="customer_near_the_kit"
                                                                label="Is the customer near the kit?"
                                                                size="small"
                                                                {...getFieldProps('customer_near_the_kit')}
                                                                disabled={!customer_available_io}
                                                                select
                                                            >
                                                                {(customer_near_the_kit_options).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between", border: 'solid 1px orange' }}>
                                                            <List sx={{ width: '100%', bgcolor: 'rgb(235, 235, 235)', fontSize: 'small' }}
                                                                subheader={<ListSubheader sx={{ padding: 1 }}>Clearly explain the promotion to the customer</ListSubheader>}>
                                                                <ListItem> Note customer's product</ListItem>
                                                                <ListItem sx={{ fontWeight: 'bold', borderBottom: 'solid 1px grey' }}> Please mention</ListItem>
                                                                <ListItem> Change to weekly payments</ListItem>
                                                                <ListItem> Minimum price will be the weekly price </ListItem>
                                                                <ListItem> Anything less than weekly price will not create token until a balance of the minimum is achieved </ListItem>
                                                            </List>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="current_phone_number"
                                                                label="Current phone number"
                                                                size="small"
                                                                {...getFieldProps('current_phone_number')}
                                                                error={Boolean(touched.current_phone_number && errors.current_phone_number)}
                                                                helperText={touched.current_phone_number && errors.current_phone_number}
                                                                disabled={!customer_available_io}
                                                            ></TextField>
                                                        </Box>


                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="alternative_phone_numbers"
                                                                label="Alternative phone numbers"
                                                                multiline
                                                                rows={2}
                                                                {...getFieldProps('alternative_phone_numbers')}
                                                                error={Boolean(touched.alternative_phone_numbers && errors.alternative_phone_numbers)}
                                                                helperText={touched.alternative_phone_numbers && errors.alternative_phone_numbers}
                                                                disabled={!customer_available_io}
                                                            ></TextField>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="notes"
                                                                label="Notes"
                                                                multiline
                                                                rows={4}
                                                                size="small"
                                                                {...getFieldProps('notes')}
                                                                error={Boolean(touched.notes && errors.notes)}
                                                                helperText={touched.notes && errors.notes}
                                                                disabled={!customer_available_io}
                                                            ></TextField>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between", border: 'solid 1px #B46C10' }}>
                                                            <List sx={{ width: '100%', bgcolor: 'rgb(235, 235, 235)', fontSize: 'small' }}
                                                                subheader={<ListSubheader sx={{ padding: 1 }}>Before saving the survey</ListSubheader>}>
                                                                <ListItem> Let's change the offer and generate an update token for the cusotmer </ListItem>
                                                                <ListItem sx={{ fontWeight: 'bold', borderBottom: 'solid 1px grey' }}> Please note</ListItem>
                                                                <ListItem> By clinking the &nbsp; <b>"Change Offer"</b> &nbsp; button, customer will get an SMS </ListItem>
                                                                <ListItem> Ask the customer to confirm receiving the SMS </ListItem>
                                                                <ListItem> Read the generated token to the customer </ListItem>
                                                                <ListItem>
                                                                    <LoadingButton
                                                                        color="warning"
                                                                        size="small"
                                                                        onClick={handleChangeOffer}
                                                                        variant="contained"
                                                                        disabled={!customer_near_device_io}
                                                                        loading={isChanging}>
                                                                        Change Offer
                                                                    </LoadingButton>
                                                                </ListItem>
                                                            </List>
                                                        </Box>

                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="token_response"
                                                                label="Token Reponse"
                                                                multiline
                                                                rows={4}
                                                                size="small"
                                                                {...getFieldProps('token_response')}
                                                                error={Boolean(touched.token_response && errors.token_response)}
                                                                helperText={touched.token_response && errors.token_response}
                                                                disabled={true}
                                                            ></TextField>
                                                        </Box>

                                                    </Grid>
                                                    {/* Common survey form ends here */}


                                                </Grid>

                                                <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                                    <Button type='reset' color="error">Reset</Button>
                                                    <LoadingButton
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={isSubmitting}>
                                                        Submit
                                                    </LoadingButton>
                                                </Stack>
                                            </Form>
                                        </FormikProvider>
                                    </Box>
                                </CardContent>
                            </Card>
                        </>
                        )
                    }
                </Grid>
            </Grid>
            {/* </Container> */}
        </Page>
    )

};


export default PortfolioHealthOfferChange;