import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { createBrowserHistory } from 'history';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// store
import { store, persistor } from './redux/store';
import LoadingScreen from './components/common/LoadingScreen';
import NotistackProvider from './components/common/NotistackProvider';
import JwtProvider from 'src/components/Auth/JwtProvider';

// ----------------------------------------------------------------------


const history = createBrowserHistory();

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <ThemeConfig>
                    <ScrollToTop />
                    <GlobalStyles />
                    <BaseOptionChartStyle />
                    <NotistackProvider>
                        <JwtProvider>
                            <Router history={history}>
                            </Router>
                        </JwtProvider>


                    </NotistackProvider>
                </ThemeConfig>
            </PersistGate>
        </Provider>
    );
}
