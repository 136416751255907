import * as React from 'react';
import * as Yup from 'yup';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    Card, CardHeader, CardContent,
    Divider,
    Stack,
    TextField,
    Button,
    Container,
    Grid,
    List, ListItem, ListItemText,
    DialogContent, DialogContentText, DialogTitle, Dialog, DialogActions,
    Slide,
    OutlinedInput,
    InputAdornment,
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer,
    MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';

// redux
import { searchCustomer, getCustomerSurveys, addCustomerSurvey } from 'src/redux/slices/customers/portfolioHealth';
import { getSurveyQuestions } from 'src/redux/slices/portfolio_health/getSurveyQuestions';

import Label from 'src/components/Label';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const PortfolioHealthSearchCustomer = () => {

    const [customer, setCustomer] = useState();
    const [account, setAccount] = useState(undefined);

    const [recent_surveys, setRecentSurveys] = useState([]);

    const [still_interested, set_still_interested] = useState(false);
    const [away_from_home, set_away_from_home] = useState(false);
    const [has_technical_issue, set_has_technical_issue] = useState(false);
    const [ability_to_continue_paying, set_ability_to_continue_paying] = useState(false);

    const [willing_to_offset_io, set_willing_to_offset_io] = useState();
    const [cc_willing_to_offset_io, set_cc_willing_to_offset_io] = useState();
    const [customer_available_io, set_customer_available_io] = useState(false);

    const org_options = [
        { k: 'none', v: 'Choose organization' }, 
        { k: 'pawame', v: 'pawame' }, 
        { k: 'mwezi limited', v: 'mwezi limited' }, 
        { k: 'mwezi solar limited', v: 'mwezi solar limited' },
        { k: 'ignite solrm', v: 'ignite solrm' } ];
    const org_codes = { 'none': 'none', 'pawame': 'pawame', 'mwezi limited': 'OR000081', 'mwezi solar limited': 'OR001014', 'ignite solrm':'solrm' };
    const tf_options = ['yes', 'no'];
    const [system_usage_frequency_options, set_system_usage_frequency_options] = useState([]);
    const [customer_available_options, set_customer_available_options] = useState([]);
    const [system_use_options, set_system_use_options] = useState([]);
    const [reason_for_not_iterested_options, set_reason_for_not_iterested_options] = useState([]);
    const [reason_for_not_paying_options, set_reason_for_not_paying_options] = useState([]);
    const [not_paying_away_from_home_response_options, set_not_paying_away_from_home_response_options] = useState([]);
    const [not_paying_has_technical_issue_response_options, set_not_paying_has_technical_issue_response_options] = useState([]);
    const [ready, set_customer_selection_ready] = useState(false);

    const [org_id, setOrgId] = useState('none');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const FormSchema = Yup.object().shape({
        phone_number_used: Yup.string().required('Field is required'),
        customer_available: Yup.string().required('Field is required'),
    });

    const formik = useFormik({
        initialValues: {
            phone_number_used: '',
            customer_available: '',

            system_usage_frequency: '',
            system_used_for: '',
            new_solar_system: '',
            reason_not_interested: '',
            reason_for_not_paying: '',
            not_paying_away_from_home_response: '',
            not_paying_has_technical_issue_response: '',
            still_interested_with_system: '',
            ability_to_continue_paying: '',
            aware_of_repossession: '',
            aware_of_premium_advantages: '',
            willing_to_offset: '',
            offset_amount: 0,
            offset_date: null,
            start_date_for_daily_payments: null,
            viable_repossession: '',
            reponsible_for_missing_payments: '',
            notes: '',

            cc_customer_accepted_to_pay: '',
            cc_offset_date: null,
            cc_no_of_installments: 0,
            cc_any_product_issue: '',
            cc_notes: '',

            org_id: ''
        },
        validationSchema: FormSchema,
        onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                const newSurvey = {
                    ...values
                };
                if (org_codes[org_id] === 'pawame') {
                    newSurvey['account'] = account.id;
                }
                else if (org_codes[org_id] === 'solrm') {
                    newSurvey['solrm_account'] = account.id;
                }
                else {
                    newSurvey['associated_account'] = account.id;
                }
                addCustomerSurvey(newSurvey).then((response) => {
                    if (response.status === 201) {
                        resetForm();
                        setAccount(undefined);
                        setCustomer(undefined);
                        setRecentSurveys([]);
                        onOrgChange('none');
                        enqueueSnackbar('Customer survey form saved', {
                            variant: 'success',
                            action: (key) => (
                                <Button size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </Button>
                            )
                        });
                    } else {
                        setErrors({ afterSubmit: response.data.details });
                    }
                }).catch((error) => {
                    setErrors({ afterSubmit: 'Error submitting' });
                });
            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.code || error.message });
                    enqueueSnackbar('Error saving', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                }
            }
        },
        onReset: async () => {
        }
    });

    const {
        errors, touched, values, isSubmitting, initialValues,
        handleSubmit, getFieldProps,
    } = formik;


    const handleAccountChoose = (row) => {
        setCustomer(row.customer);
        setAccount(row.account);
        // load customer surveys
        getCustomerSurveys(row.account.id, org_codes[org_id]).then((response) => {
            if (response.status === 200) {
                setRecentSurveys(response.data.results);
            }
        }).catch(() => {

        });
    };

    const handleAutoFillChoose = (survey) => {
        survey.date_created = undefined;
        (Object.keys(survey)).map((key) => {

            formik.setFieldValue(key, survey[key]).then(() => {

            }).catch(() => {

            });
        });
    };

    const onOrgChange = (value) => {
        setOrgId(value);
        set_customer_selection_ready((value === 'none') ? false : true);
        formik.resetForm();
        setAccount(null);
        setCustomer(null);
        setRecentSurveys([]);
    };

    const dispatch = useDispatch();

    const [groupIdParams, setgroupIdParams] = useSearchParams();
    let groupId = groupIdParams.get("groupId").trim();

    const { questions, status } = useSelector(state => state.questions);


    useEffect(() => {

        if (status === 'idle') {
            dispatch(getSurveyQuestions());
        }

        if (groupId == 85) {
            if (questions.length > 0) {
                set_customer_available_options(questions.filter((question_) => question_.question_key == 'customer_available')[0].answers);
                set_system_usage_frequency_options(questions.filter((question_) => question_.question_key == 'system_usage_frequency')[0].answers);
                set_system_use_options(questions.filter((question_) => question_.question_key == 'system_use')[0].answers);
                set_reason_for_not_iterested_options(questions.filter((question_) => question_.question_key == 'reason_for_not_iterested')[0].answers);
                set_reason_for_not_paying_options(questions.filter((question_) => question_.question_key == 'reason_for_not_paying')[0].answers);
                set_not_paying_away_from_home_response_options(questions.filter((question_) => question_.question_key == 'not_paying_away_from_home_response')[0].answers);
                set_not_paying_has_technical_issue_response_options(questions.filter((question_) => question_.question_key == 'not_paying_has_technical_issue_response')[0].answers);
            }
            set_still_interested((values.still_interested_with_system == 'yes'));
            set_ability_to_continue_paying((values.still_interested_with_system == 'yes'));
            set_willing_to_offset_io((values.willing_to_offset == 'yes'));
            set_away_from_home((values.reason_for_not_paying == 'Away from home'));
            set_has_technical_issue((values.reason_for_not_paying == 'Technical issue'));
        } else if (groupId == 6) {
            if (questions.length > 0) {
                set_customer_available_options(questions.filter((question_) => question_.question_key == 'customer_available')[0].answers);
            }
            set_cc_willing_to_offset_io((values.cc_customer_accepted_to_pay == 'yes'));
        }

        set_customer_available_io((values.customer_available == 'Available' && account !== undefined));

    }, [values, account, status, dispatch]);

    const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
        width: 240,
        transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`
        }
    }));



    const SearchCustomerDialog = ({ handleAccountChoose, ready, org_id }) => {

        const [busyPlaceholder, setBusyPlaceholder] = useState('No records');

        const [filterName, setFilterName] = useState('');

        const [open, setOpen] = React.useState(false);

        const [rows, setRows] = useState([]);

        useEffect(() => {

        }, []);


        const handleClickOpen = () => {
            setOpen(true);
            setFilterName('');
            setBusyPlaceholder('');
            setRows([]);
        };

        const handleClose = () => {
            setOpen(false);
            setFilterName('');
            setBusyPlaceholder('');
            setRows([]);
        };


        const handleCardClick = (row) => {
            handleAccountChoose(row);
            setOpen(false);
            setFilterName('');
            setRows([]);
        };


        const handleKeypress = (event) => {

            if (event.which === 13) {
                // check if enough characters
                if (filterName.length < 8) {
                    enqueueSnackbar('Search term is short.  Enter more than 8', {
                        variant: 'error',
                        action: (key) => (
                            <Button size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </Button>
                        )
                    });
                    return;
                } else {
                    setBusyPlaceholder(`Searching ${filterName} in ${org_id}...`)
                    searchCustomer(filterName, org_codes[org_id]).then((response) => {
                        if (response.status === 200) {
                            setRows(response.data.results);
                            setBusyPlaceholder(`${response.data.results.length} customers found for ${filterName} in ${org_id}.`)
                        } else {
                            setBusyPlaceholder(`No customers found for ${filterName} in ${org_id}.`)
                        }
                    }).catch(() => {
                        setBusyPlaceholder(`No customers found for ${filterName} in ${org_id}.`)
                    });
                }

            }
        };

        const handleChange = (event) => {
            setFilterName(event.target.value);
            setRows([]);
        };


        return (
            <div style={{ width: '100%' }}>
                {ready === false ?
                    (<></>) :
                    (<>
                        <Button size="medium" onClick={handleClickOpen} endIcon={<Icon icon={searchFill} />}>
                            Search customer
                        </Button>

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            fullScreen={true}
                            aria-describedby="alert-dialog-slide-description"
                            style={{
                                marginTop: 10,
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 10
                            }}
                        >
                            <DialogTitle>{`Search customer in ${org_id}`}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Enter phone number or id (Press enter to search). Click on the card to choose
                                </DialogContentText>
                                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                                    <Stack spacing={3} width="100%" style={{ padding: 10 }}>
                                        <SearchStyle
                                            size='small'
                                            value={filterName}
                                            onChange={handleChange}
                                            onKeyPress={handleKeypress}
                                            placeholder={'Search customer by mpesa, phone no or nationa id'}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            }
                                        />
                                        <Divider />
                                        <Grid container spacing={2}>
                                            {rows.length > 0 ?
                                                (<>
                                                    {rows.map((row) => {
                                                        return <div>
                                                            <Card sx={{ maxWidth: '100%', padding: 2, margin: 2 }} >
                                                                <CardHeader
                                                                    sx={{ textDecoration: 'none' }}
                                                                    title={row.full_name}
                                                                    subheader={`Mobile: ${row.mobile_number} Mpesa: ${row.mpesa_mobile}`}
                                                                />
                                                                <CardContent>
                                                                    <List sx={{ width: '100%', maxWidth: 550, bgcolor: 'background.paper' }} >
                                                                        {
                                                                            row.account.map((account => (
                                                                                <>
                                                                                    <ListItem key={account.id} alignItems="flex-start">
                                                                                        <ListItemText
                                                                                            primary={account.account_no}
                                                                                            key={account.id}
                                                                                            secondary={
                                                                                                <React.Fragment>
                                                                                                    <Typography variant="body2" color="text.primary">
                                                                                                        {account.payment_plan}
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                                        {account.account_status}
                                                                                                    </Typography>
                                                                                                    <Button color="primary" onClick={() => { handleCardClick({ customer: row, account: account }) }}>
                                                                                                        Choose
                                                                                                    </Button>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        />
                                                                                    </ListItem>
                                                                                </>
                                                                            )))
                                                                        }
                                                                    </List >
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    })}
                                                </>) :
                                                (<>{busyPlaceholder}</>)}
                                        </Grid>
                                    </Stack>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color="error" onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </>)}
            </div>
        )
    }

    return (
        <Page title="Portfolio health - New survey">
            {/* <Container> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {`Customer porfolio health`}
                        </Typography>
                    </Stack>
                    {status === 'loading' ?
                        <Loader /> :
                        (<>
                            <Card>
                                <CardHeader title={`New survey`} />
                                <CardContent>
                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <TextField
                                            id="org_id"
                                            label="Organization"
                                            size="small"
                                            onChange={e => onOrgChange(e.target.value)}
                                            value={org_id}
                                            select
                                        >
                                            {(org_options).map((option) => (
                                                <MenuItem key={option.k} value={option.v} disabled={(option.k === 'none')} selected={(org_id === option.k)} >
                                                    {option.v.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Divider />
                                    &nbsp;
                                    &nbsp;

                                    <SearchCustomerDialog handleAccountChoose={handleAccountChoose} ready={ready} org_id={org_id} />
                                    &nbsp;
                                    &nbsp;


                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
                                        <Grid item xs={12} sm={12} md={5} sx={{ mt: 1, display: 'flex', flexDirection: "column" }}>
                                            <Divider />
                                            <TableContainer sx={{ minWidth: 200 }}>
                                                <Table size='small'>
                                                    <caption>Customer and loan info</caption>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Names</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.full_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Plan</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.payment_plan}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Phone</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.mpesa_mobile}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Loan #</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.account_no}
                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Location</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {customer?.region?.county?.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>Balance</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {account?.payoffamount}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>

                                                            <TableCell>
                                                                <strong>Last payment date</strong>
                                                            </TableCell>
                                                            <TableCell>
                                                                {(account?.last_payment_datetime !== undefined) ? (moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(account?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button color="primary" component={RouterLink} to={`/dashboard/ticket/create/${account?.account_no}/?callingNumber=${customer?.mpesa_mobile}`} >
                                                                    Create ticket
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex', flexDirection: "column", marginLeft: 3 }}>
                                            <TableContainer sx={{ minWidth: 200, maxHeight: 200, overflowY: 'scroll' }}>
                                                <Table size='small'>
                                                    <TableHead sx={{}}>
                                                        <TableRow >
                                                            {['Executive', 'Created on', 'Date of offset', 'Start of daily payments', 'Why not paying', 'Notes', 'Auto-fill'].map((th_row) => {
                                                                return (
                                                                    <TableCell>{th_row}</TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <caption>Recent surveys</caption>
                                                    <TableBody>
                                                        {recent_surveys.length > 0 ?
                                                            (<>
                                                                {recent_surveys.map((recent_survey) => {
                                                                    return <TableRow>
                                                                        <TableCell>
                                                                            {recent_survey?.created_by.first_name} {recent_survey?.created_by.last_name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {moment(new Date(recent_survey?.date_created).toLocaleString()).format("YYYY-MM-DD")}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(recent_survey?.offset_date !== undefined) ? (moment(new Date(recent_survey?.offset_date).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(recent_survey?.offset_date).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {/* {moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD")} */}
                                                                            {(recent_survey?.start_date_for_daily_payments !== undefined) ? (moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {recent_survey?.reason_for_not_paying}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {recent_survey?.notes}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Button color="primary" size="small" onClick={() => { handleAutoFillChoose(recent_survey); }}>
                                                                                Choose
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                })}
                                                            </>) :
                                                            (<></>)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>

                                        <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex', flexDirection: "column", marginLeft: 3 }}>
                                            <TableContainer sx={{ minWidth: 200, maxHeight: 200, overflowY: 'scroll' }}>
                                                <Table size='small'>
                                                    <TableHead sx={{}}>
                                                        <TableRow >
                                                            {['Executive', 'Created on', 'Date of offset', 'Start of daily payments', 'Why not paying', 'Notes', 'Auto-fill'].map((th_row) => {
                                                                return (
                                                                    <TableCell>{th_row}</TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <caption>Tickets</caption>
                                                    <TableBody>
                                                        {recent_surveys.length > 0 ?
                                                            (<>
                                                                {recent_surveys.map((recent_survey) => {
                                                                    return <TableRow>
                                                                        <TableCell>
                                                                            {recent_survey?.created_by.first_name} {recent_survey?.created_by.last_name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {moment(new Date(recent_survey?.date_created).toLocaleString()).format("YYYY-MM-DD")}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(recent_survey?.offset_date !== undefined) ? (moment(new Date(recent_survey?.offset_date).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(recent_survey?.offset_date).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {/* {moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD")} */}
                                                                            {(recent_survey?.start_date_for_daily_payments !== undefined) ? (moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(recent_survey?.start_date_for_daily_payments).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {recent_survey?.reason_for_not_paying}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {recent_survey?.notes}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Button color="primary" size="small" onClick={() => { handleAutoFillChoose(recent_survey); }}>
                                                                                Choose
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                })}
                                                            </>) :
                                                            (<></>)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Box>

                                    <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                                        <Label variant="h6">
                                            Fill form
                                        </Label>
                                        <FormikProvider value={formik}>
                                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>

                                                    {/* Common survey form starts here */}
                                                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="phone_number_used"
                                                                label="Phone number used to call"
                                                                size="small"
                                                                {...getFieldProps('phone_number_used')}
                                                                error={Boolean(touched.phone_number_used && errors.phone_number_used)}
                                                                helperText={touched.phone_number_used && errors.phone_number_used}
                                                            ></TextField>
                                                        </Box>
                                                        <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                            <TextField
                                                                id="customer_available"
                                                                label="Customer available"
                                                                size="small"
                                                                {...getFieldProps('customer_available')}
                                                                error={Boolean(touched.customer_available && errors.customer_available)}
                                                                helperText={touched.customer_available && errors.customer_available}
                                                                select
                                                            >
                                                                {(customer_available_options).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Box>
                                                    </Grid>
                                                    {/* Common survey form ends here */}

                                                    {/* TC survey form starts here */}
                                                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }} visibility={(groupId == 85) ? "visible" : "hidden"}>
                                                        <Grid item xs={12} sm={12} md={4} sx={{ padding: 1 }}>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="system_usage_frequency"
                                                                    label="How often do you use system?"
                                                                    size="small"
                                                                    {...getFieldProps('system_usage_frequency')}
                                                                    error={Boolean(touched.system_usage_frequency && errors.system_usage_frequency)}
                                                                    helperText={touched.system_usage_frequency && errors.system_usage_frequency}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(system_usage_frequency_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="system_used_for"
                                                                    label="System used for"
                                                                    size="small"
                                                                    {...getFieldProps('system_used_for')}
                                                                    error={Boolean(touched.system_used_for && errors.system_used_for)}
                                                                    helperText={touched.system_used_for && errors.system_used_for}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(system_use_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="new_solar_system"
                                                                    label="Any new solar systems?"
                                                                    size="small"
                                                                    {...getFieldProps('new_solar_system')}
                                                                    error={Boolean(touched.new_solar_system && errors.new_solar_system)}
                                                                    helperText={touched.new_solar_system && errors.new_solar_system}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="still_interested_with_system"
                                                                    label="Still interested / Ability to continue paying?"
                                                                    size="small"
                                                                    {...getFieldProps('still_interested_with_system')}
                                                                    error={Boolean(touched.still_interested_with_system && errors.still_interested_with_system)}
                                                                    helperText={touched.still_interested_with_system && errors.still_interested_with_system}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="reason_not_interested"
                                                                    label="Reason for not interested"
                                                                    size="small"
                                                                    {...getFieldProps('reason_not_interested')}
                                                                    disabled={still_interested}
                                                                    select
                                                                >
                                                                    {(reason_for_not_iterested_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                            {/* <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }} >
                                                                <TextField
                                                                    id="ability_to_continue_paying"
                                                                    label="Ability to continue paying?"
                                                                    size="small"
                                                                    {...getFieldProps('ability_to_continue_paying')}
                                                                    error={Boolean(touched.ability_to_continue_paying && errors.ability_to_continue_paying)}
                                                                    helperText={touched.ability_to_continue_paying && errors.ability_to_continue_paying}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box> */}

                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }} hidden>
                                                                <TextField
                                                                    id="reason_for_not_paying"
                                                                    label="Reason for not paying / able to pay"
                                                                    size="small"
                                                                    {...getFieldProps('reason_for_not_paying')}
                                                                    disabled={!still_interested}
                                                                    select
                                                                >
                                                                    {(reason_for_not_paying_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="not_paying_away_from_home_response"
                                                                    label="Away from home response"
                                                                    size="small"
                                                                    {...getFieldProps('not_paying_away_from_home_response')}
                                                                    disabled={!away_from_home}
                                                                    select
                                                                >
                                                                    {(not_paying_away_from_home_response_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="not_paying_has_technical_issue_response"
                                                                    label="Has technical issue response"
                                                                    size="small"
                                                                    {...getFieldProps('not_paying_has_technical_issue_response')}
                                                                    disabled={!has_technical_issue}
                                                                    select
                                                                >
                                                                    {(not_paying_has_technical_issue_response_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>

                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} sx={{ padding: 1 }}>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="aware_of_repossession"
                                                                    label="Aware of repossession?"
                                                                    size="small"
                                                                    {...getFieldProps('aware_of_repossession')}
                                                                    error={Boolean(touched.aware_of_repossession && errors.aware_of_repossession)}
                                                                    helperText={touched.aware_of_repossession && errors.aware_of_repossession}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="aware_of_premium_advantages"
                                                                    label="Aware of premium advantages?"
                                                                    size="small"
                                                                    {...getFieldProps('aware_of_premium_advantages')}
                                                                    error={Boolean(touched.aware_of_premium_advantages && errors.aware_of_premium_advantages)}
                                                                    helperText={touched.aware_of_premium_advantages && errors.aware_of_premium_advantages}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="willing_to_offset"
                                                                    label="Willing to offset?"
                                                                    size="small"
                                                                    {...getFieldProps('willing_to_offset')}
                                                                    error={Boolean(touched.willing_to_offset && errors.willing_to_offset)}
                                                                    helperText={touched.willing_to_offset && errors.willing_to_offset}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="offset_amount"
                                                                    label="Offset amount"
                                                                    size="small"
                                                                    {...getFieldProps('offset_amount')}
                                                                    disabled={!willing_to_offset_io}
                                                                ></TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="offset_date"
                                                                    label="Date of offset"
                                                                    type="date"
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    {...getFieldProps('offset_date')}
                                                                    disabled={!willing_to_offset_io}
                                                                ></TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="start_date_for_daily_payments"
                                                                    label="Start date for daily payments"
                                                                    type="date"
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    {...getFieldProps('start_date_for_daily_payments')}
                                                                    disabled={!customer_available_io}
                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} sx={{ padding: 1 }}>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="viable_repossession"
                                                                    label="Viable repossession"
                                                                    size="small"
                                                                    {...getFieldProps('viable_repossession')}
                                                                    error={Boolean(touched.viable_repossession && errors.viable_repossession)}
                                                                    helperText={touched.viable_repossession && errors.viable_repossession}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="reponsible_for_missing_payments"
                                                                    label="Pawame responsible for missing payments?"
                                                                    size="small"
                                                                    {...getFieldProps('reponsible_for_missing_payments')}
                                                                    error={Boolean(touched.reponsible_for_missing_payments && errors.reponsible_for_missing_payments)}
                                                                    helperText={touched.reponsible_for_missing_payments && errors.reponsible_for_missing_payments}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="notes"
                                                                    label="Notes"
                                                                    multiline
                                                                    rows={4}
                                                                    size="small"
                                                                    {...getFieldProps('notes')}
                                                                    error={Boolean(touched.notes && errors.notes)}
                                                                    helperText={touched.notes && errors.notes}
                                                                // disabled={!customer_available_io}
                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    {/* TC survey form ends here */}


                                                    {/* CC survey form starts here */}
                                                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }} marginTop={(groupId == 6) ? -45 : 0} visibility={(groupId == 6) ? "visible" : "hidden"}>
                                                        <Grid item xs={12} sm={12} md={4} sx={{ padding: 1 }}>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="cc_customer_accepted_to_pay"
                                                                    label="Has customer accepted to pay?"
                                                                    size="small"
                                                                    {...getFieldProps('cc_customer_accepted_to_pay')}
                                                                    error={Boolean(touched.cc_customer_accepted_to_pay && errors.cc_customer_accepted_to_pay)}
                                                                    helperText={touched.cc_customer_accepted_to_pay && errors.cc_customer_accepted_to_pay}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="cc_offset_date"
                                                                    label="Date of offset"
                                                                    type="date"
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    {...getFieldProps('cc_offset_date')}
                                                                    disabled={!cc_willing_to_offset_io}
                                                                ></TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="cc_no_of_installments"
                                                                    label="No of installments"
                                                                    size="small"
                                                                    type="number"
                                                                    {...getFieldProps('cc_no_of_installments')}
                                                                    error={Boolean(touched.cc_no_of_installments && errors.cc_no_of_installments)}
                                                                    helperText={touched.cc_no_of_installments && errors.cc_no_of_installments}
                                                                    disabled={!customer_available_io}
                                                                ></TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="cc_any_product_issue"
                                                                    label="Any product issue?"
                                                                    size="small"
                                                                    {...getFieldProps('cc_any_product_issue')}
                                                                    error={Boolean(touched.cc_any_product_issue && errors.cc_any_product_issue)}
                                                                    helperText={touched.cc_any_product_issue && errors.cc_any_product_issue}
                                                                    disabled={!customer_available_io}
                                                                    select
                                                                >
                                                                    {(tf_options).map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            {option.toUpperCase()}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <Box sx={{ mt: 1, display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                                                <TextField
                                                                    id="cc_notes"
                                                                    label="Notes"
                                                                    multiline
                                                                    rows={4}
                                                                    size="small"
                                                                    {...getFieldProps('cc_notes')}
                                                                    error={Boolean(touched.cc_notes && errors.cc_notes)}
                                                                    helperText={touched.cc_notes && errors.cc_notes}
                                                                // disabled={!customer_available_io}
                                                                ></TextField>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    {/* CC survey form ends here */}
                                                </Grid>

                                                <Stack sx={{ margin: "0.5em", display: 'flex', flexDirection: 'row', justifyContent: 'right', }}>
                                                    <Button type='reset' color="error">Reset</Button>
                                                    <LoadingButton
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={isSubmitting}>
                                                        Submit
                                                    </LoadingButton>
                                                </Stack>
                                            </Form>
                                        </FormikProvider>
                                    </Box>
                                </CardContent>
                            </Card>
                        </>
                        )
                    }
                </Grid>
            </Grid>
            {/* </Container> */}
        </Page>
    )

};


export default PortfolioHealthSearchCustomer;