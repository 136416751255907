import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
// material
import {
    Button,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';

// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Ticket #', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'date', label: 'Dated', alignRight: false },
    { id: 'created_by', label: 'Created by', alignRight: false },
    { id: 'assigned_to', label: 'Assigned by', alignRight: false },
    { id: 'escalated_to', label: 'Escalated by', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
];

const useStyles = makeStyles(() => ({
    root: {
    },
    kit: {
        margin: "1px",
        backgroundColor: 'ButtonShadow',
        borderRadius: '5px'
    }

}));


const Tickets = ({ customerTickets, customerDetails }) => {
    const customer = (customerDetails) ? customerDetails : {};
    const tickets = (customerTickets) ? customerTickets : [];
    return (
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map((ticket) => {
                            return (
                                <TableRow
                                    hover
                                    key={ticket.id}
                                    tabIndex={-1}
                                >
                                    <TableCell component={RouterLink} to={`/dashboard/ticket-details/${ticket?.id}`} sx={{ color: 'text.secondary' }}>{ticket.id}</TableCell>
                                    <TableCell>{ticket.category?.name}</TableCell>
                                    <TableCell>{ticket.status}</TableCell>
                                    <TableCell>{ticket.date}</TableCell>
                                    <TableCell>{ticket.created_by?.email}</TableCell>
                                    <TableCell>{ticket.assigned_to?.email}</TableCell>
                                    <TableCell>{ticket.escalated_to?.email}</TableCell>
                                    <TableCell>{ticket.description}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
    );
}


export default Tickets;
